const markdown = `
* a style guide contains general rules about "how to write" code, 
* e.g. which quotes to use, how many spaces to indent, the maximal line length, etc. 

popular choices:
* [Google JavaScript Style Guide](https://google.github.io/styleguide/jsguide.html)
* [Airbnb JavaScript Style Guide](https://github.com/airbnb/javascript)
* [Idiomatic.JS](https://github.com/rwaldron/idiomatic.js)
* [StandardJS](https://standardjs.com/)

### linters
* are tools that can automatically check the style of your code and make improving suggestions.
* great thing about them is that style-checking can also find some bugs, like typos in variable or function names.
* because of this feature, using a linter is recommended even if you don't want to stick to one particular "code style".

some well-known linting tools:
* JSLint - one of the first linters.
* JSHint - more settings than JSLint.
* ESLint - probably the newest one. (authors'pick)
`

export default markdown