const title = 'Rust'
const data = [
  {
    text: 'Rust',
    url: 'https://www.rust-lang.org/',
    info: ''
  },
  {
    text: 'Learn Rust',
    url: 'https://doc.rust-lang.org/book/',
    info: ''
  },
  {
    text: 'WebAssembly',
    url: 'https://www.rust-lang.org/what/wasm',
    info: ''
  },
  {
    text: 'Rust to WebAssembly: Compiling',
    url: 'https://developer.mozilla.org/en-US/docs/WebAssembly/Rust_to_wasm',
    info: ''
  }
]

const obj = { title, data }

export default obj