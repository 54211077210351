const markdown = `

* Full Screen: \`F11\`

* Markdown Preview
  * Window: \`CTRL + SHIFT + V\`
  * Sideview: \`CTRL + K\` then \`V\`

* Zen Mode: \`CTRL + K\` then \`Z\`

* Zoom
  * In: \`CTRL + =\`
  * Out: \`CTRL + -\`
`

export default markdown