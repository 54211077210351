const markdown = `
the css transform property takes functions

scale function
* example: double the size of all the div elements
  * \`div {transform:scale(2);}\`
* example: interactive scaling
  * \`#grow:hover {transform: scale(2.1);}\`

translate function
* repositions an element in the horizontal and/or vertical directions

skewX function
* skews the selected element along its X (horizontal) axis by a given degree.
  * \`div {transform: skewX(-32deg);}\`

skewY function 
* skews the selected element along its Y (vertical) axis by a given degree.
  * \`div {transform: skewY(-32deg);}\`
`

export default markdown