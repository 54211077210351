import { useNavigate } from "react-router-dom";
import List from "../comps/List"
import getFName from "../func/getFName";

export default function Main({ data }) {
  const lists = data.slice(0,1).map((d,i) => <List key={i} list={d}/>)
  const sectionLinks = data.slice(1).map((d,i) => <SectionLink key={i} list={d}/>)
  return (
    <>
      {lists}
      <section className='grid'>{ sectionLinks }</section>
    </>
  )
}

function SectionLink({ list}) {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/${getFName(list.title)}`);
  return (
    <div 
      onClick={handleClick} 
      className='clickable m-2 p-2 text-white bg-dark text-center'
    >
      {list.title}
    </div>
  )
}