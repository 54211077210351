import { useNavigate } from "react-router-dom";
import getFName from "../func/getFName"

export default function ListPages ({ pages, path }) {
  const lines = pages.map((d, i) => <Line key={i} line={d} path={path}/>)
  return (
    <section>
      <h3 className="fs-5">Notes</h3>
      <ul>
        {lines}
      </ul>
    </section>
  )
}

function Line({ line, path }) {
  const { title } = line
  const url = path.concat(`/${getFName(title)}`)
  const navigate = useNavigate();
  const handleClick = () => navigate(url);
  return <li onClick={handleClick} className="clickable text-decoration-underline">{title}</li>
}