import './App.css';
import { Outlet, useNavigate } from 'react-router-dom'

function App() {
  const navigate = useNavigate();
  const handleClick = () => navigate('/');
  return (
    <main className="container">
      <header>
        <h1 onClick={handleClick} className='clickable text-center'>Dev Notes</h1>
      </header>
      <Outlet/>
    </main>
  )
}

export default App;
