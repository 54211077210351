const markdown = `
* domain name = user friendly name
* three parts: *third-level-domain.second-level-domain.top-level-domain*:
  * first-level: top level domains e.g. *.com*, *.org*, etc
  * second-level: unique to the company or organization that registers it
  * third-level: (aka subdomain)
    * often directs you to a subset of the website: *m.wikipedia.org* (mobile-optimized Wikipedia)

can't buy, can only lease
* cannot "buy a domain name". 
* instead, you pay for the right to use a domain name for one or more years. 
* you can renew your right, and your renewal has priority over other people's applications.

side note: what exactly is the "www?" 
* technically, it's a subdomain traditionally used to indicate that a site is part of the web, as opposed to some other part of the Internet like Gopher or FTP
`

export default markdown