const markdown = `
* animation properties and 
* the \`@keyframes\` rule.

animation properties
* animation properties control how the animation behaves
  * \`animation-name\`: sets the name of the animation, which is later used by @keyframes
  * \`animation\`: shorthand for multiple animation properties
  * \`animation-delay\`: sets when an animation starts
  * \`animation-direction\`: play animation forwards, backwards, or alternating back and forth
  * \`animation-duration\`: sets the length of time for the animation
  * \`animation-fill-mode\`: sets how ananimation applies styles to its target before and after its execution
  * \`animation-iteration-count\`: how many times you would like to loop through the animation
  * \`animation-play-state\`: sets whether an animation is running or paused
  * \`animation-timing-function\`: sets how an animation progresses through the duration of each cycle.

keyframes
* for 0% the keyword \`from\` can be used instead
* for 100% the keyword \`to\` can be used instead

example
\`\`\`css
#anim {  
  animation-name: colorful;
  animation-duration: 3s;
}  
@keyframes colorful {  
  0% {  
  background-color: blue;  
  }  
  100% {  
  background-color: yellow;  
  }  
}
\`\`\`
`

export default markdown