const markdown = `
protocol
* system of rules that define how data is exchanged within or between computers. 

many different protocols are needed at various layers:
* Application (BGP, DHCP(v6), DNS, FTP, HTTP, HTTPS, IMAP, IRC, LDAP, MGCP, MQTT, NNTP, NTP, OSPF, SMTP, TLS/SSL, etc)
* Transport (TCP, UDP, DCCP, SCTP, RSVP, etc)
* Internet (IP(IPv4, IPv6), ICMP(v6), NDP, ECN, IGMP, etc)
* Link (Tunnels, PPP, MAC)

point A to point B
* data typically travels by going through the stack of one device across the internet and then up the stack of the destination device
![alt](/images/internet-stack.png)  

encapsulation
* as gets capsulated and then uncapsulated  
![alt](/images/capsule.png)  
`

export default markdown