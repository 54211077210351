const markdown = `
* @rule = css statement that instructs css how to behave
* each type of @rule has its own internal syntax and semantics:
  * those to convey metadata: \`@charset\`, \`@import\`
  * descriptive information: \`@font-face\`
  * conditional information (nested statements): \`@media\`, \`@document\`

@charset
* Defines the character set used by the style sheet.  
* \`@charset "utf-8";\`

@import
* Tells the CSS engine to include an external style sheet.  
* \`@import url("bluish.css");\`
* \`@import 'custom.css';\`
`

export default markdown