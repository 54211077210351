const title = 'SEO'
const data = [
  {
    text: "GitHub's SEO Guide",
    url: 'https://github.com/seo/guide',
    info: ''
  },
  {
    text: "Google's SEO Documentation",
    url: 'https://developers.google.com/search/docs',
    info: ''
  },
  {
    text: 'Medium SEO Quick Overview',
    url: 'https://medium.com/welldone-software/seo-for-developers-a-quick-overview-5b5b7ce34679',
    info: ''
  }
]

const obj = { title, data }

export default obj