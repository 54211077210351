const markdown = `
* circular queue is a queue that writes to the end of a collection 
* then begins overwriting itself at the beginning of the collection. 
* a circular queue can be used for streaming media. 
* once the queue is full, new media data will overwrite old data.
\`\`\`
class CircularQueue {
  constructor(size) {

    this.queue = [];
    this.read = 0;
    this.write = 0;
    this.max = size - 1;

    while (size > 0) {
       this.queue.push(null);
       size--;
    }
  }

  print() {
    return this.queue;
  }

  enqueue(item) {
   // write head
   let e = this.queue[this.write]
   if(e) return null
   this.queue[this.write] = item
   this.write = (this.write < this.max) ? this.write + 1 : 0
   return item    
  }

  dequeue() {
   // read head
   let e = this.queue[this.read]
   if(!e) return null
   let item = e;
   this.queue[this.read] = null;
   this.read = (this.read < this.max) ? this.read + 1 : 0
   return item
  }
}
\`\`\`
`

export default markdown