import env from "./pages/env"

const title = 'NodeJS'
const data = [
  {
    text: 'official docs',
    url: 'https://nodejs.org/en/docs/',
    info: ''
  },
  {
    text: 'official guide',
    url: 'https://nodejs.org/en/docs/guides/',
    info: ''
  }
]

const pages = [
  {
    title: '.env file',
    text: env
  }
]

const obj = { title, data, pages }

export default obj