const markdown = `
* enqueue method for adding items with a priority
* dequeue method for removing and returning items
\`\`\`
function PriorityQueue () {
  this.collection = [];
  this.printCollection = function() {
    console.log(this.collection);
  };
  // Only change code below this line
  this.isEmpty =function(){
    return this.collection.length === 0
  };
  this.size = function(){
    return this.collection.length
  };
  this.front = function(){
    return this.collection[0]
  };
  this.dequeue = function(){
    return this.collection.shift()[0]
  };
  this.enqueue = function(e){
    const collectionLength = this.collection.length
    let added = false;

    for(let i = 0; i < collectionLength; i++){
      let item = this.collection[i]
      if(e[1] < item[1]) {
        this.collection.splice(i, 0, e)
        added = true
        break
      }
    }
    if(!added) this.collection.push(e)
  };
  // Only change code above this line
}
\`\`\`
`

export default markdown