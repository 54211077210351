const markdown = `
Add an extra cursor

* Add Manually: \`ALT + CLICK\`

* Add Relative to Current Position
  * Above: \`CTRL + ALT + UP\`
  * Below: \`CTRL + ALT + DOWN\`

* Add to All Instances: \`CTRL + SHIFT + I\`
* To Only Next Instance: \`CTRL + D\`
`

export default markdown