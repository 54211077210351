const markdown = `
New
* File: \`CTRL + N\`
* Window: \`CTRL + SHIFT + N\`

Open
* File: \`CTRL + O\`
* Last Closed File: \`CTRL + SHIFT + T\`
* Current File (In New Window): \`CTRL + K\` then \`O\`
* File Link: 
  * Quickly open a file or image or create a new file by moving the cursor to the file link
  * \`CTRL + CLICK\` on link

Save
* Save: \`CTRL + S\`
* Save As: \`CTRL + SHIFT + S\`
* Save All: \`CTRL + K\` then \`S\`

Close
* File: \`CTRL + F4\`
* All Files: \`CTRL + K\` then \`CTRL + W\`
* Folder: \`CTRL + K\` then \`F\`
`

export default markdown