const markdown = `
* url = web address
* can be composed of a web words (“www.bbc.co.uk”) or an IP address (192.68.20.50)

syntax
* a web address has syntax rules: scheme://prefix.domain:port/path/filename
  * Scheme - defines the type of internet service (most common is http or https)
  * Prefix - defines a domain prefix (default for http is www)
  * Domain - defines the internet domain name (e.g. bbc.co.uk)
  * Port - defines the port number at the host (default for http is 80)
  * Path - defines a path at the server (if omitted this is the root directory of the site)
  * Filename - the name of a document or resource


urls and ascii
* urls can only be sent over the internet using the ASCII character set
* if a URL contains characters outside the ASCII set the URL has to be converted using URL encoding
* for example, URL coding converts non-ASCII characters with a "%" followed by hexadecimal digits, spaces are replaced with a "+" or "%20"
`

export default markdown