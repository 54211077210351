const markdown = `
how a hash table works:
* takes a key input and hashes this key in a deterministic way to some numerical value
* this numerical value is then used as the actual key the associated value is stored by
* if you try to access the same key again, 
* the hashing function will process the key, return the same numerical result, 
* which will then be used to look up the associated value. 
* this provides very efficient O(1) lookup time on average.


as arrays
* Hash tables can be implemented as arrays with hash functions 
* thus producing array indices within a specified range. 

collisions
* the choice of the array size is important, as is the hashing function. 
* For instance, what if the hashing function produces the same value for two different keys? 
* This is called a collision. 
* One way to handle collisions is to just store both key-value pairs at that index. 
* Then, upon lookup of either, you would have to
* iterate through the bucket of items to find the key you are looking for. 
* A good hashing function will minimize collisions to maintain efficient search time.
~~~js
// if i just used this.collection[k] might get collisions
// so have use this.collection[k][key]

var called = 0;
var hash = string => {
  called++;
  var hashed = 0;
  for (var i = 0; i < string.length; i++) {
    hashed += string.charCodeAt(i);
  }
  return hashed;
};
var HashTable = function() {
  this.collection = {};
  // change code below this line
  this.add = function(key, value) {
    let k = hash(key)
    let hasK = this.collection.hasOwnProperty(k)
    if(!hasK) this.collection[k] = {}
    this.collection[k][key] = value
  };
  this.remove = function(key) {
    let k = hash(key)
    let hasK = this.collection.hasOwnProperty(k)
    if(hasK && this.collection[k].hasOwnProperty(key)) {
      delete this.collection[k][key];
    }
  };
  this.lookup = function(key) {
    let k = hash(key)
    let hasK = this.collection.hasOwnProperty(k)
    if(hasK && this.collection[k].hasOwnProperty(key)) {
      return this.collection[k][key];
    }
    return null;
  }
  // change code above this line
};
~~~
`

export default markdown