const markdown = `
shallow techniques
1. for in loop: \`let clone = {}; for (let key in user) { clone[key] = user[key];}\`
1. Object.assign(): \`let clone = Object.assign({}, user);\`
1. spread syntax: \`let clone = {...user}\`

deep techniques
1. json stringify and parse
1. lodash: for instance \`_.cloneDeep(obj)\`
`

export default markdown