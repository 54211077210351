export default function List({ list }) {
  const { title, data } = list
  const lines = data.map((d, i) => <Line key={i} line={d}/>)
  return (
    <section>
      <h2 className="fs-5">{title}</h2>
      <ul>
        {lines}
      </ul>
    </section>
  )
}

function Line({ line }) {
  const { text, url, info } = line
  const infoText = info.length > 0 ? `: ${info}` : ''
  return (
    <li>
      <a target="_blank" rel="noreferrer" href={url}>{text}</a>{infoText}
    </li>
  )
}