const markdown = `
Run Menu = Debugging Menu

* Select 'Run and Debug'
  * if working on a node app select NodeJs as your environment
* Debugger is now attached
* You can see of the app is running by going to *localhost:3000* in your browser

launch.json
* for a more customisable debugging
* *launch.json* is like a recipe for how to start your app in debug mode
* to create *launch.json* file
  * select the gear icon
  * *launch.json* file will be added to *.vscode* folder
* configure launch.json file
  * go to the *launch.json* file
  * click on the add configuration button (this is also in the run menu) for suggested configurarions
  * example:
    * add line: \`"stopOnEntry" : true\`
    * now when the app is run it will stop first thing

breakpoint submenu
* bottom of screen
* displays a list of breakpoints (including their filename and line number)
* show options for adding function breakpoints, deactivating breakpoints and removing all break points
`

export default markdown