import general from "./general/index";
import vsc from "./vsc/index"
import git from "./git/index";
import gitbash from "./gitbash/index"
import github from "./github/index"
import html from "./html/index";
import css from "./css/index";
import js from "./js/index";
import hosting from "./hosting/index"
import npm from "./npm/index"
import nodejs from "./nodejs/index"
import apis from "./apis/index"
import react from "./react/index"
import ssgs from "./ssgs/index"
import dnd from "./dnd/index"
import testing from "./testing/index"
import internet from "./internet/index"
import websec from "./websec/index"
import seo from "./seo/index"
import datasStrucs from "./datastruc/index"
import databases from "./databases/index"
import rust from "./rust/index"

const all = [
  general,
  vsc,
  git,
  gitbash,
  github,
  html,
  css,
  js,
  hosting,
  npm,
  nodejs,
  apis,
  react,
  ssgs,
  dnd,
  testing,
  internet,
  websec, 
  seo,
  datasStrucs,
  databases,
  rust
]

export default all