const markdown = `
what?
* HTTPS is encrypted in order to increase security of data transfer. 
* particularly important when users transmit sensitive data.
* any website, especially those that require login credentials, should use HTTPS.

why?
* HTTPS prevents websites from having their information broadcast in a way that's easily viewed by anyone snooping on the network. 
* issue:
  * when info is sent over regular HTTP
  * info is broken into packets of data that can be easily "sniffed" using free software. 
  * makes communication over the an unsecure medium, such as public Wi-Fi, highly vulnerable to interception. 
  * all communications that occur over HTTP occur in plain text, making them highly accessible to anyone with the correct tools, and vulnerable to on-path attacks.
* solution
  * with HTTPS, traffic is encrypted such that even if the packets are sniffed or otherwise intercepted, they will come across as nonsensical characters. 
  * example: 
    * before encryption: \`This is a string of text that is completely readable\`
    * after encryption: \`ITM0IRyiEhVpa6VnKyExMiEgNveroyWBPlgGyfkflYjDaaFf/Kn3bo3OfghBPDWo6AfSHlNtL8N7ITEwIXc1gU5X73xMs\`

how?
* HTTPS uses an encryption protocol to encrypt communications. 
* protocol is called Transport Layer Security (TLS), although formerly it was known as Secure Sockets Layer (SSL). 
* protocol secures communications by using what's known as an asymmetric public key infrastructure. 
* this type of security system uses two different keys to encrypt communications between two parties:
  * private key
    * this key is controlled by the owner of a website and it's kept private.
    * this key lives on a web server and is used to decrypt information encrypted by the public key.
  * public key
    * this key is available to everyone who wants to interact with the server in a way that's secure.
    * info that's encrypted by the public key can only be decrypted by the private key.

difference between HTTPS and HTTP?
* technically speaking, HTTPS is not a separate protocol from HTTP. 
* simply using TLS/SSL encryption over the HTTP protocol. 
* HTTPS occurs based upon the transmission of TLS/SSL certificates
* which verify that a particular provider is who they say they are.
* how?
  * when a user connects to a webpage, the webpage will send over its SSL certificate which contains the public key necessary to start the secure session. 
  * the two computers, the client and the server, then go through a process called an SSL/TLS handshake, which is a series of back-and-forth communications used to establish a secure connection. 
  * to take a deeper dive into encryption and the SSL/TLS handshake, read about what happens in a TLS handshake [here](https://www.cloudflare.com/learning/ssl/what-happens-in-a-tls-handshake/).
`

export default markdown