const markdown = `
* click on source control
* click initialize repository
  * bottom left corner that the branch is now named main
  * To rename go to command palette and type rename

untracked files
* untracked files will have a u beside them
* to track an untracked file
  * click on the + sign next to u
  * file now in staging area

new branch
* add new features to your code => create a new branch
* command palette then type 'create branch', give it a title like 'new features'

changes
* add new lines to your file => LHS gutter = green
* change an existing line in your file => LHS gutter = blue
* delete an existing line in your file => LHS gutter = red arrow

inline view
* in the upper right corner click inline view 
* will display your changes more explicitly - you can see the differences

merging
* want the new code to be reflected in the main branch? you need to merge
* click on Three dots => branch => merge branch then click on the branch you were working on

github
* publishing code (sending it to github)
* click on publish branch and follow the steps

clone code from github
* Command palette
* type git: clone
* paste the url
* select a location







`

export default markdown