const markdown = `
* you computer can't store a database of more than 300 million domain names locally
* multi-step process to find out the IP address.

dns servers
* DNS = distributed database
* keeps track of computer's names and their corresponding IP addresses on the Internet.
* many computers connected to the Internet host part of the DNS database and the software that allows others to access it. 
* these computers are known as DNS servers. 
* no DNS server contains the entire database; they only contain a subset of it. 
* if a DNS server does not contain the domain name requested by another computer, the DNS server re-directs the requesting computer to another DNS server.

your dns server
* every device connected to the internet uses a dns server
* this is usually which ever one your operating system has by default
* it is possible to change your dns server

domain name to ip address
* in bash: type nslookup www.google.com

dns refreshing
* DNS databases are stored on every DNS server worldwide
* all these servers refer to a few special servers called "authoritative name servers" or "top-level DNS servers." — these are like the boss servers that manage the system.
* whenever your registrar creates or updates any information for a given domain, the information must be refreshed in every DNS database. 
* each DNS server that knows about a given domain stores the information for some time before it is automatically invalidated and then refreshed (the DNS server queries an authoritative server and fetches the updated information from it). 
* thus, it takes some time for DNS servers that know about this domain name to get the up-to-date information.

`

export default markdown