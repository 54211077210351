const markdown = `
* working directory - where you do all your work
* staging area - where you list changes made to the working directory
* local repo - where git permanently stores these changes made to the project
* remote repo - github, gitLab, bitBucket, etc

![alt text](/images/git-workflow.png)

team workflow  
in order to collaborate, need
* a complete replica of the project on your own computers
* a way to keep track of and review each others code
* access to a definitive version (master)

This is accomplished using a **remote**
* remote = shared git repository
* collabarators work individulally then merge changes together when they are ready to do so


1. fetch and merge changes from the remote
1. create a branch to work on a new project feature
1. develop the feature on your branch and commit your work
1. fetch and merge from the remote again (in case new commits were made while you were working)
1. push your branch up to the remote for review  

(steps 1-4 are intended to safeguard against merge conflicts)
`

export default markdown