const markdown = `
* change the presentation of content based on different viewport sizes
  * e.g. \`@media (max-width: 100px)\`
    * runs when the device's width is less than or equal to 100px
  * e.g. \`@media (min-height: 350px)\`
    * runs when the device's height is more than or equal to 350px

### screen sizes
\`\`\`
/* Extra small devices (phones, 600px and down) */  
@media only screen and (max-width: 600px) {

}
\`\`\`
\`\`\`
/* Small devices (portrait tablets and large phones, 600px and up) */  
@media only screen and (min-width: 600px) {

}
\`\`\`
\`\`\`
/* Medium devices (landscape tablets, 768px and up) */  
@media only screen and (min-width: 768px) {

}
\`\`\`
\`\`\`
/* Large devices (laptops/desktops, 992px and up) */  
@media only screen and (min-width: 992px) {

}
\`\`\`
\`\`\`
/* Extra large devices (large laptops and desktops, 1200px and up) */  
@media only screen and (min-width: 1200px) {

}
\`\`\`
`

export default markdown