const title = 'Design & Development Principles'
const data = [
  {
    text: 'KISS: Keep It Simple, Stupid',
    url: 'https://deviq.com/principles/keep-it-simple',
    info: ''
  },
  {
    text: "YAGNI: You Aren't Going to Need It",
    url: 'https://deviq.com/principles/yagni',
    info: ''
  },
  {
    text: "DRY: Don't Repeat Yourself",
    url: 'https://dzone.com/articles/software-design-principles-dry-and-kiss',
    info: ''
  },
  {
    text: 'TDD: Test Driven Development',
    url: 'https://www.guru99.com/test-driven-development.html',
    info: ''
  }
]

const obj = { title, data }

export default obj