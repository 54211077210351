const markdown = `
* popular American web infrastructure and website security company that provides
  * content delivery network
  * DDoS mitigation services
* its services sit between a website's visitor and the Cloudflare customer's hosting provider, acting as a reverse proxy for websites

reverse proxy
* in computer networks, a reverse proxy is a type of proxy server
* retrieves resources on behalf of a client from one or more servers.
* these resources are then returned to the client, appearing as if they originated from the reverse proxy server itself.
* it is mainly used to balance load.
`

export default markdown