const markdown = `
* each time the terminal is launched = new session
* settings and preferences can be configured and loaded when a new session begins

nano (cl text editor)
* works like a desktop editor like notepad

bash profile
* ~/.bash_profile -name of file used to store environment settings
* ~ represents user's home directory
* . hidden files
* alias allows yoy to create shortcuts
  * e.g alias pd="pwd"

environment variables
* variables that can be used across commands and programs
* e.g. \`export USER = "Jane Doe"\`
* note: $ is always used when return a variables value e.g. \`echo $USER\`
* \`PS1\` = variable that defines the makeup and styple of command prompt
* \`HOME\` = displays the path of the home directory

PATH
* variable simply lists which directories contain scripts
* each directory contains scripts for the command line to execute
* i.e many commands are scripts stored in the /bin directory
* e.g script executed when you type pwd is in the /bin directory
* this list of directories separated by a colon
* e.g. /home/ccuser/.gem/ruby/2.0.0/bin:/user/local/sbin:/user/local/sbin
* in advanced cases, you can customize the path variable when adding scripts of your own

env (environment)
* returns a list of environmentvariables for the current user
* \`env | grep PATH\` displays the value of the PATH variable
`

export default markdown