const markdown = `
types
* \`while\`: condition is checked before each iteration.
* \`do..while\`: condition is checked after each iteration.
* \`for (;;)\` - condition is checked before each iteration, additional settings available.

break directive
* To make an "infinite" loop, usually the while(true) construct is used. 
* such a loop, just like any other, can be stopped with the \`break\` directive.

continue directive
* don't want to do anything in the current iteration and would like to forward to the next one - use the \`continue\` directive.

labels
* break/continue support labels before the loop. 
* a label is the only way for break/continue to escape a nested loop to go to an outer one.

labelled example
\`\`\`
outer: for (let i = 0; i < 3; i++) {  
  for (let j = 0; j < 3; j++) {  
    let input = prompt('Value at coords ($i,$j)', '');  
    // if an empty string or canceled, then break out of both loops  
    if (!input) break outer; // (*)  
    // do something with the value...  
  }  
}  
\`\`\`
`

export default markdown