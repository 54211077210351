import basicCommands from './pages/basic-commands'
import intro from "./pages/intro"
import packageStr from "./pages/package"
import moduleStr from "./pages/module"
import publishing from "./pages/creating-a-package"

const title = 'NPM'
const data = [
  {
    text: 'cli commands',
    url: 'https://docs.npmjs.com/cli/v6/commands',
    info: ''
  },
  {
    text: 'beginners guide',
    url: 'https://nodesource.com/blog/an-absolute-beginners-guide-to-using-npm/',
    info: ''
  },
  {
    text: 'intro to npm scripts',
    url: 'https://www.geeksforgeeks.org/introduction-to-npm-scripts/',
    info: ''
  },
  {
    text: 'contributing packages to the registry',
    url: 'https://docs.npmjs.com/packages-and-modules/contributing-packages-to-the-registry',
    info: ''
  },
  {
    text: 'code security: npm audit',
    url: 'https://docs.npmjs.com/packages-and-modules/securing-your-code',
    info: ''
  }
]

const pages = [
  {
    title: 'introduction',
    text: intro
  },
  {
    title: 'simple commands',
    text: basicCommands
  },
  {
    title: 'package',
    text: packageStr
  },
  {
    title: 'module',
    text: moduleStr
  },
  {
    title: 'publishing to npm',
    text: publishing
  }
]

const obj = { title, data, pages }

export default obj