const markdown = `
* /libs 
  * usually used for custom classes/functions/modules
* /vendor or /support 
  * contains 3rd party libraries (added as git sub-module when using git as source control)
  * both /vendor and /support are deprecated since NPM introduced a clean package management. It's recommended to handle all 3rd-party dependencies using NPM and a package.json file
* /spec 
  * contains specifications for BDD tests.
* /tests 
  * contains the unit-tests for an application (using a testing framework, see here)

When building a rather large application, I recommend the following additional folders (especially if you are using some kind of MVC- / ORM-Framework like express or mongoose):

* /models 
  * contains all your ORM models (called Schemas in mongoose)
* /views 
  * contains your view-templates (using any templating language supported in express)
* /public 
  * contains all static content (images, style-sheets, client-side JavaScript)
* /assets
  * /assets/images 
    * contains image files
  * /assets/pdf 
    * contains static pdf files
* /css 
  * contains style sheets (or compiled output by a css engine)
* /js 
  * contains client side JavaScript
* /controllers
  * contain all your express routes, separated by module/area of your application (note: when using the bootstrapping functionality of express, this folder is called /routes)
`

export default markdown