import intro from "./pages/intro"
import typedArrays from "./pages/typedarrays"
import stackLifo from "./pages/stack-lifo"
import queueFifo from "./pages/queue-fifo"
import queuePriority from "./pages/queue-priority"
import queueCircular from "./pages/queue-circular"
import classSet from "./pages/class-set"
import map from "./pages/map"
import hashTable from "./pages/hash-table"

const title = 'Data Structures'
const data = [
  {
    text: 'FCC: Data Structures',
    url: 'https://www.freecodecamp.org/learn/coding-interview-prep/#data-structures',
    info: ''
  },
  {
    text: 'Introduction',
    url: 'https://www.studytonight.com/data-structures/introduction-to-data-structures',
    info: ''
  },
  {
    text: 'Common Data Structures',
    url: 'https://www.freecodecamp.org/news/the-top-data-structures-you-should-know-for-your-next-coding-interview-36af0831f5e3/',
    info: ''
  },
  {
    text: 'Wiki Book on Data Structures',
    url: 'https://en.wikibooks.org/wiki/Data_Structures',
    info: ''
  }
]

const pages = [
  {
    title: "Introduction",
    text: intro
  },
  {
    title: "Typed Arrays",
    text: typedArrays
  },
  {
    title: "Stack: Last In First Out",
    text: stackLifo
  },
  {
    title: "Queue: First In First Out",
    text: queueFifo
  },
  {
    title: "Queue: Priority",
    text: queuePriority
  },
  {
    title: "Queue: Circular",
    text: queueCircular
  },
  {
    title: "Class: Set",
    text: classSet
  },
  {
    title: "Map",
    text: map
  },
  {
    title: "Hash Table",
    text: hashTable
  }
]

const obj = { title, data, pages }

export default obj