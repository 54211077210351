const markdown = `
* looking for existing issues you can fix?
* every open source project has a /contribute page that highlights beginner-friendly issues you can start out with. 
  * example https://github.com/facebook/react/contribute

useful resources to help you discover and contribute to new projects:
* [GitHub Explore](https://github.com/explore/)
* [Open Source Friday](https://opensourcefriday.com/)
* [First Timers Only](https://www.firsttimersonly.com/)
* [CodeTriage](https://www.codetriage.com/)
* [24 Pull Requests](https://24pullrequests.com/)
* [Up For Grabs](https://up-for-grabs.net/)
* [Contributor-ninja](https://contributor.ninja/)
* [First Contributions](https://firstcontributions.github.io/)
* [SourceSort](https://web.archive.org/web/20201111233803/https://www.sourcesort.com/)
`

export default markdown