const markdown = `
Editor
* Close Window: \`CTRL + W\`
* Tabbing
  * Between: \`CTRL + TAB\`
  * Previous: \`ALT + LEFT\`
  * Next: \`ALT + RIGHT\`
* Split: \`CTRL + /\`

Editors  
Can drag and drop editors to create new editor groups.
* Switch Between Editors: \`CTRL + 1\`, \`CTRL + 2\`, \`CTRL + 3\`, etc

Side Panel
* Toggle: \`CTRL + B\`
* Goto
  * Explorer: \`CTRL + SHIFT + E\`
  * Search: \`CTRL + SHIFT + F\`
  * Source Control: \`CTRL + SHIFT + G\`
  * Run & Debug: \`CTRL + SHIFT + D\`
  * Extensions: \`CTRL + SHIFT + X\`

Bottom Panel
* Toggle: \`CTRL + J\`
* Goto
  * Problems: \`CTRL + SHIFT + M\`
  * Output: \`CTRL + SHIFT + U\`
  * Debug Console: \`CTRL + SHIFT + Y\`
  * Terminal: \`CTRL + SHIFT + '\`
`

export default markdown