const markdown = `
* simple configuration text file
* used to define variables you want to pass into your application's environment.
* parser is needed
  * file needs a something like a parser to make it work. 
  * parser reads the variable definitions one-by-one and parses them to the environment. 
  * uses the format ENV_VARIABLE=VALUE (in the case of Node.js: process.env[ENV_VARIABLE]=VALUE).
  * this is not a built-in feature in Node.js
  * you have to engineer it with a popular module called dotenv.
`

export default markdown