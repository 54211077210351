const markdown = `
commenting 
\`/* COMMENT HERE */\`

syntax
* declaration:  \`prop:val\`
* declaration \`block {prop:val}\`
* selector \`h1 {prop:val}\`

usage
* inline style attribute \`style="color:blue”;\`
* style tags \`<style>h1 {color: blue;}</style>\`
* style sheet \`<link rel="stylesheet" href="./styles.css">\`

variables
* declaring \`--penguin-skin: gray;\`
* using \`background: var(--penguin-skin);\`

inheritance
* tag selector < class selector < id selector < inline style < !important

### basic selectors
* type: \`p {color:red}\`
* class: \`.done {color:red}\`
* id: \`#warn {color:red}\`
* universal: \`* {color:red}\`

### advanced selectors
attribute
* \`[attr]\` have 'attr' set
* \`[attr=val]\` have 'attr' & attribute's value is equal to 'val'
* \`[attr~=val]\` have 'attr' & if that attribute's **space separated list of values** is equal to 'val'

combinators
* selects all elements that A,B is A and/or B
  * \`A B\` - descendant of A & is a B element
  * \`A > B\` - direct child of A & is a B element
  * \`A + B\` - next sibling of A & is a B element
  * \`A ~ B\` - one of next siblings of A & is a B element

pseudo-Classes
* used to specify that you want to select certain elements when they are in a specific state
* syntax[selector]:[keyword] {css rule}
  * \`:active\`
  * \`:first\`
  * \`:hover\`
  * \`:empty\`

pseudo-Elements
* used to specify that you want to select a certain part of an element
* syntax \`[selector]::[keyword] {css rule} \`
  * \`::after\`
  * \`::before\`
  * \`::first-letter\`
  * ...and many more
`

export default markdown