import relational from "./pages/relational"
import nosql from "./pages/nosql"

const title = 'Databases'
const data = [
  {
    text: 'Relational Databases',
    url: 'https://www.ibm.com/cloud/learn/relational-databases',
    info: ''
  },
  {
    text: 'SQL: Relational Databases',
    url: 'https://en.wikipedia.org/wiki/SQL',
    info: ''
  },
  {
    text: 'NO SQL',
    url: 'https://en.wikipedia.org/wiki/NoSQL',
    info: ''
  },
  {
    text: 'GraphQL',
    url: 'https://en.wikipedia.org/wiki/GraphQL',
    info: ''
  }
]

const pages = [
  {
    title: "Relational Databases",
    text: relational
  },
  {
    title: "No SQL Databases",
    text: nosql
  }
]


const obj = { title, data, pages }

export default obj