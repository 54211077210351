const title = 'HTML'
const data = [
  {
    text: 'Best Practices',
    url: 'https://github.com/hail2u/html-best-practices',
    info: ''
  },
  {
    text: 'HTML Entities',
    url: 'https://www.w3schools.com/charsets/ref_html_entities_4.asp',
    info: 'common escape sequences'
  },
  {
    text: 'Form Validation (MDN)',
    url: 'https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation',
    info: ''
  },
  {
    text: 'Accessibility: Developer Tool',
    url: 'https://accessibilityinsights.io/',
    info: ''
  },
  {
    text: 'Accessibility: Guide',
    url: 'https://www.smashingmagazine.com/2021/03/complete-guide-accessible-front-end-components/',
    info: ''
  }
]

const obj = { title, data }

export default obj