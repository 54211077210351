const markdown = `
syntax
* single line: \`// This comment occupies a line of its own\`
* multiline: \`/* multiline comment.*/\`

bad comments
* novices tend to use comments to explain "what is going on in the code". 
* like this: \`// This code will do this thing (...) and that thing (...)\`

comments should be minimal
* in good code, the amount of such "explanatory" comments should be minimal.
* the code should be easy to understand without them.
* great rule about that: **"if the code is so unclear that it requires a comment, then maybe it should be rewritten instead"**.

good comments
* describe the architecture
* provide a high-level overview of components: bird's eye view of the code. 
* There's a special language [UML](https://en.wikipedia.org/wiki/Unified_Modeling_Language) to build high-level architecture diagrams explaining the code. Definitely worth studying.

documenting a function
* there's a special syntax [JSDoc](http://en.wikipedia.org/wiki/JSDoc) to document a function: usage, parameters, returned value.
  
For instance:  
\`\`\`
/**  
 * Returns x raised to the n-th power.  
 *  
 * @param {number} x The number to raise.  
 * @param {number} n The power, must be a natural number.  
 * @return {number} x raised to the n-th power.  
 */  
function pow(x, n) {  
  ...  
}  
\`\`\`
Such comments allow us to understand the purpose of the function and use it the right way without looking in its code.

generating documentation
- tools like JSDoc 3 that can generate HTML-documentation from the comments. 
- You can read more information about JSDoc at https://jsdoc.app.
`

export default markdown