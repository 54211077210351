const markdown = `
terms
* parent element known as **container**
* chilren elements known as **items**

units
* can use absolute and relative units
* these units are useful:
  * \`fr\`: sets the column or row to a fraction of the available space
  * \`auto\`: sets the column or row to the width or height of its content automatically
  * \`%\`: adjusts the column or row to the percent width of its container

setting
* display: grid
* columns
  * eg. \`grid-template-columns: 50px 50px;\`
  * ie. two columns that are 50px wide each 
* rows
  * eg. \`grid-template-rows: 20px 20px 20px\`
  * ie. three rows that are 20px height each

useful functions
* \`repeat()\`
  * eg. \`grid-template-columns: repeat(2, 1fr 50px) 20px\`
  * ie. same as grid-template-columns: 1fr 50px 1fr 50px 20px;
* \`minmax()\`
* \`autofill()\`
* \`autofit()\`

gaps
* useful properties when setting gaps between rows and columns
  * \`grid-row-gap\`
  * \`grid-column-gap\`
  * \`grid-gap\`

### grid-templates-area property
\`\`\` css
.container {  
  grid-template-areas:  
    "header header header"  
    "advert content content"  
    "footer footer footer";  
}
\`\`\`
* every word in the code represents a cell
* every pair of quotation marks represent a row
* in addition to custom labels, you can use a period (.) to designate an empty cell in the grid.

using the grid-template-area
* place an item in your custom area by referencing the name you gave it
  * eg. \`.item1 { grid-area: header; }\`

working on the fly
* you can do this without a reference (have no grid-template-area)
  * to do this you will have to size the item
    * can do this with grid-area prop (or by using both grid-column and grid-row)
  * eg. \`item1 { grid-area: 1/1/3/4; }\`
  * ie. horiz line start/vert line start/horiz line end/vert line end
![alt text](/images/css-grid-lines.png)

alignment
* collective
  * horizontal: justify-items
  * vertical: align-items
* individual
  * horizontal: justify-self
  * vertical: slign-self
`

export default markdown