const markdown = `
* set is like an array, but it cannot contain duplicate values. 
* typical use for a set is to simply check for the presence of an item.
  * e.g \`const set1 = new Set([1, 2, 3, 5, 5, 2, 0]); // {1, 2, 3, 5, 0}\`
~~~js
class Set {
  constructor() {
  // collection will hold our set
  this.collection = [];
  }
  // this method will check for the presence of an element and return true or false
  has(element) {
      return this.collection.indexOf(element) !== -1;
  }
  // this method will return all the values in the set
  values() {
      return this.collection;
  }
  // change code below this line

  // write your add method here
  add(val){
      let exists = this.collection.includes(val)
      if(exists) return false
      this.collection.push(val)
      return true;
  }
  // write your remove method here
  remove(val){
      let index = this.collection.indexOf(val)
      if(index === -1) return false
      this.collection.splice(index, 1)
      return true;
  }
  // write your size method here
  size(){
      return this.collection.length
  }
  // change code above this line
}
~~~
`

export default markdown