const markdown = `
* is any file or directory in the node_modules directory that can be loaded by the Node.js require() function.
* to be loaded by the Node.js require() function, a module must be one of the following:
  * a folder with a package.json file containing a "main" field.
  * a JavaScript file.
* note: 
  * since modules are not required to have a 'package.json' file, not all modules are packages.
  * only modules that have a 'package.json' file are also packages.
* in the context of a Node program, the module is also the thing that was loaded from a file. 
  * e.g. \`var req = require('request')\`
  * we might say that "The variable req refers to the request module".
`

export default markdown