const markdown = `
flags
* -S :same as \`--save\`
* -D :same as \`--save-dev\`
* -G : global package

basics
* install: \`npm i <package>\`
* uninstall: \`npm un <package>\`
* update: \`npm up <package>\`

global packages
* show all global packages: \`npm list -g --depth=0\`
* show outdated packages: \`npm outdated -g --depth=0\`
`

export default markdown