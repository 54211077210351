const title = 'APIs'
const data = [
  {
    text: 'Rest: What?',
    url: 'https://www.codecademy.com/article/what-is-rest',
    info: ''
  },
  {
    text: 'Rest: What? 2',
    url: 'https://www.redhat.com/en/topics/api/what-is-a-rest-api',
    info: ''
  },
  {
    text: 'Rest: Deeper Dive',
    url: 'https://www.ics.uci.edu/~fielding/pubs/dissertation/rest_arch_style.htm',
    info: ''
  },
  {
    text: 'Authentication: OAuth - What?',
    url: 'https://developer.okta.com/blog/2017/06/21/what-the-heck-is-oauth',
    info: ''
  },
  {
    text: 'Authentication: OAuth - Intro',
    url: 'https://www.digitalocean.com/community/tutorials/an-introduction-to-oauth-2',
    info: ''
  },
  {
    text: 'Authentication: JWT - What?',
    url: 'https://www.akana.com/blog/what-is-jwt',
    info: ''
  },
  {
    text: 'Authentication: JWT - Intro',
    url: 'https://jwt.io/introduction',
    info: ''
  }
]

const obj = { title, data }

export default obj