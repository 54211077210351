const markdown = `
* usually listed in the top level of a repository.
* LICENSE: 
  * by definition, every open source project must have an open source license. 
  * if **project does not have a license, it is not open source**.
* README: 
  * The README is the instruction manual that welcomes new community members to the project.
  * explains why the project is useful and how to get started.
* CONTRIBUTING: 
  * contributing docs help people contribute to the project.
  * explains what types of contributions are needed and how the process works. 
  * while not every project has a CONTRIBUTING file, its presence signals that this is a welcoming project to contribute to.
* CODE_OF_CONDUCT:
  * sets ground rules for participants behavior associated and helps to facilitate a friendly, welcoming environment.
  * not every project has a CODE_OF_CONDUCT file, its presence signals that this is a welcoming project to contribute to.
* Other documentation: 
  * might be additional documentation, such as tutorials, walkthroughs, or governance policies, especially on bigger projects.
`

export default markdown