import opsoPeople from "./pages/opso_people"
import opsoProjDocs from "./pages/opso_proj-docs"
import opsoProjTools from "./pages/opso-proj_tools"
import opsoFind from "./pages/opso_finding"

const title = 'GitHub'
const data = [
  {
    text: 'Official Docs',
    url: 'https://docs.github.com/en',
    info: ''
  },
  {
    text: 'Quickstart for Markdown on GitHub',
    url: 'https://guides.github.com/features/mastering-markdown/#what',
    info: ''
  },
  {
    text: 'Open Source Projects: Contributing',
    url: 'https://opensource.guide/how-to-contribute/',
    info: ''
  },
  {
    text: 'Open Source Projects: How to Contribute',
    url: 'https://opensource.guide/how-to-contribute/#how-to-submit-a-contribution',
    info: ''
  },
  {
    text: 'Open Source Projects: Open an Issue',
    url: 'https://opensource.guide/how-to-contribute/#opening-an-issue',
    info: ''
  },
  {
    text: 'Open Source Projects: Open Pull Request',
    url: 'https://opensource.guide/how-to-contribute/#opening-a-pull-request',
    info: ''
  },
  {
    text: 'Licensing: Github',
    url: 'https://docs.github.com/en/repositories/managing-your-repositorys-settings-and-features/customizing-your-repository/licensing-a-repository',
    info: ''
  },
  {
    text: 'Licensing: Choosing A License',
    url: 'https://choosealicense.com/',
    info: ''
  },
  {
    text: 'Licensing: Open Source Guide',
    url: 'https://opensource.guide/legal/#which-open-source-license-is-appropriate-for-my-project',
    info: ''
  },
  {
    text: 'Licensing: Licensing Models',
    url: 'https://www.10duke.com/software-licensing-models/',
    info: ''
  },
  {
    text: 'Licensing: Adopting Open Source',
    url: 'https://www.software.ac.uk/resources/guides/adopting-open-source-licence',
    info: ''
  }
]

const pages = [
  { 
    title: "Open Source: People",
    text: opsoPeople
  },
  { 
    title: "Open Source: Project Documentation",
    text: opsoProjDocs
  },
  { 
    title: "Open Source: Project Tools",
    text: opsoProjTools
  },
  { 
    title: "Open Source: Finding a Project",
    text: opsoFind
  }
]
const obj = { title, data, pages }

export default obj