import cli from "./pages/cli"
import cliEnv from "./pages/cli-env"
import cliRedir from "./pages/cli-redir"
import ssh from "./pages/ssh"

const title = 'Git Bash'
const data = [
  {
    text: 'Bash Cheatsheet',
    url: 'https://devhints.io/bash',
    info: ''
  },
  {
    text: '40 Most Commonly Used Linux Commands',
    url: 'https://www.hostinger.com/tutorials/linux-commands',
    info: ''
  },
  {
    text: 'Linux Commands',
    url: 'https://www.linuxtrainingacademy.com/linux-commands-cheat-sheet/',
    info: ''
  },
  {
    text: 'Linux Cheat Sheet',
    url: 'https://www.cheatography.com/davechild/cheat-sheets/linux-command-line/pdf/',
    info: ''
  },
  {
    text: 'Bash keyboard shortcuts',
    url: 'https://www.ostechnix.com/list-useful-bash-keyboard-shortcuts/',
    info: ''
  },
  {
    text: 'Nano Guide',
    url: 'https://www.howtogeek.com/howto/42980/the-beginners-guide-to-nano-the-linux-command-line-text-editor/',
    info: ''
  },
  {
    text: 'Man Pages: Find Man Page',
    url: 'https://tldr.ostera.io/ls',
    info: ''
  },
  {
    text: 'Man Pages: Book',
    url: 'https://tldr.sh/assets/tldr-book.pdf',
    info: ''
  },
  {
    text: 'CLI crash course',
    url: 'https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Understanding_client-side_tools/Command_line',
    info: ''
  },
  {
    text: 'SSH Article',
    url: 'https://virginia.service-now.com/its?id=itsweb_kb_article&sys_id=b9ac9170db1d9b404f32fb671d96190b',
    info: ''
  }
]

const pages = [
  { 
    title: "CLI intro",
    text: cli
  },
  { 
    title: "CLI environment",
    text: cliEnv
  },
  { 
    title: "CLI redirection",
    text: cliRedir
  },
  { 
    title: "SSH",
    text: ssh
  }
]

const obj = { title, data, pages }

export default obj