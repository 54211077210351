const markdown = `
* might occasionally see it and wonder what it is

background
* for a long time, JS evolved without compatibility issues.
* new features were added while old functionality didn't change.
* benefit = never breaking existing code.
* downside = any mistake or an imperfect decision made by JS's creators got stuck in the language forever.
  
changes
* this was the case until 2009 when ECMAScript 5 (ES5) appeared. 
  * added new features to the language and modified some of the existing ones. 
  * **to keep the old code working, most such modifications are off by default.**
  * you **needed to explicitly enable them with a special directive: "use strict"**.
  * without "use strict", everything still works, 
    * but some features behave in the old-fashioned, "compatible" way, when we would generally prefer modern behaviour.

usage
* directive looks like a string: "use strict" or 'use strict'. 
* when located at the top of a script, the whole script works the "modern" way.

when you can omit It
* modern JS supports "classes" and "modules" - advanced language structures that enable use strict automatically. 
* So you don't need to add the "use strict" directive, when you use them.
* So, for now "use strict"; is a welcome guest at the top of your scripts. 
* later, when your code is all in classes and modules, you may omit it.
`
export default markdown