const markdown = `
absolute units
* tied to physical units of length.

relative units
* are relative to another length value.
* examples
  * em - is relative to the parent element's font-size
  * rem - is relative to the root (HTML) font size

viewport units
* are relative to the viewport dimensions (width or height) of a device & percentages are relative to the size of the parent container element
* four different viewport units are:
  * vw: \`10vw \`would be 10% of the viewport's width
  * vh: \`3vh\` would be 3% of the viewport's height
  * vmin: \`70vmin\` would be 70% of the viewport's smaller dimension (height vs. width)
  * vmax: \`100vmax\` would be 100% of the viewport's bigger dimension (height vs. width)
`

export default markdown