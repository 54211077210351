const markdown = `
* typical open source project has the following types of people:
  * Author: 
    * person/s or organization that created the project
  * Owner: 
    * person/s who has administrative ownership over the organization or repository 
    * (not always the same as the original author)
  * Maintainers: 
    * contributors responsible for driving the vision
    * managing the organizational aspects of the project
    * (may also be authors or owners of the project.)
  * Contributors: 
    * everyone who has contributed something back to the project
  * Community Members: 
    * people who use the project. 
    * they might be active in conversations or express their opinion on the project's direction

bigger projects
* may also have subcommittees or working groups focused on different tasks
  * such as tooling, triage, community moderation, and event organizing. 
* look on a project's website for a "team" page, or in the repository for governance documentation, to find this information.
`

export default markdown