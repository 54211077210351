const markdown = `
### variables
two rules
1. name must contain only letters, digits, or the symbols $ and _.
1. first character must not be a digit.

convention: uppercase constants
* widespread practice to use constants as aliases for difficult-to-remember values that are known prior to execution ("hard-coded values").
* such constants are named using capital letters and underscores.
* examples:
  * \`const COLOR_RED = "#F00";\`
  * \`const COLOR_GREEN = "#0F0";\`

good-to-follow rules:
* use human-readable names 
  * like userName or shoppingCart.
* stay away from abbreviations or short names
  * like a, b, c, unless you really know what you're doing.
* make names maximally descriptive and concise. 
  * examples of bad names are data and value. 
* agree on terms within your team and in your own mind. 
  * if a site visitor is called a 'user' 
  * then we should name related variables currentUser or newUser instead of currentVisitor or newManInTown.

### functions
* funcs are actions. 
* so their name is usually a verb. 
* should be **brief**, as **accurate as possible** and **describe what the function does**. 
* widespread practice to start a function with a verbal prefix which vaguely describes the action.
* function starting with…
  * "get…" – return a value,
  * "calc…" – calculate something,
  * "create…" – create something,
  * "check…" – check something and return a boolean, etc.
* examples of such names:
  * showMessage(..)     // shows a message
  * getAge(..)          // returns the age (gets it somehow)
  * calcSum(..)         // calculates a sum and returns the result
  * createForm(..)      // creates a form (and usually returns it)
  * checkPermission(..) // checks a permission, returns true/false
`

export default markdown