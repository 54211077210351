import useStrict from "./pages/use-strict"
import varNaming from "./pages/var-naming"
import bigInt from "./pages/big-int"
import userInteraction from "./pages/user-interaction"
import operators from "./pages/operators"
import labellingLoops from "./pages/loops-labels"
import funcDefaultVals from "./pages/func-default-vals"
import commenting from "./pages/commenting"
import styleGuides from "./pages/style-guides"
import destructuring from "./pages/destructuring"
import objectCloning from "./pages/object-cloning"
import thisKeyword from "./pages/this"

const title = 'JS'
const data = [
  {
    text: 'Common Escape Characters',
    url: 'https://en.wikipedia.org/wiki/Escape_character#JavaScript',
    info: ''
  },
  {
    text: 'JS Roadmap',
    url: 'https://roadmap.sh/javascript',
    info: ''
  },
  {
    text: 'MDN Javascript Reference',
    url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference',
    info: ''
  },
  {
    text: 'Modern JavaScript Tutorial',
    url: 'https://javascript.info/',
    info: ''
  },
  {
    text: 'JS Operator Precedence Table',
    url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Operator_Precedence#table',
    info: ''
  },
  {
    text: 'Regular Expressions 101',
    url: 'https://regex101.com/',
    info: 'regular expression tester'
  },
  {
    text: 'Compatability: Can I Use It?',
    url: 'https://caniuse.com',
    info: 'to check if a new feature is supported by a particular JS engine'
  },
  {
    text: 'Compatability: JS Feature/Engine Table',
    url: 'https://kangax.github.io/compat-table',
    info: ''
  },
  {
    text: 'Standards: ECMA-262',
    url: 'https://www.ecma-international.org/publications/standards/Ecma-262.htm',
    info: 'ECMAScript a JS standard intended to ensure the interoperability of web pages across different browsers'
  },
  {
    text: 'Standards: ECMA-262 Latest Draft Spec',
    url: 'https://tc39.es/ecma262/',
    info: ''
  },
  {
    text: 'Standards: ECMA-262 Proposals',
    url: 'https://github.com/tc39/proposals',
    info: "read about new features, including 'stage 3' proposals ('almost standard')"
  },
  {
    text: 'TypeScript',
    url: 'https://www.typescriptlang.org/',
    info: ""
  }
]

const pages = [
  {
    title: "style guides & linters",
    text: styleGuides
  },
  {
    title: "commenting",
    text: commenting
  },
  {
    title: "variable & function naming",
    text: varNaming
  },
  {
    title: "understanding 'use strict' directive",
    text: useStrict
  },
  {
    title: "BigInt data type",
    text: bigInt
  },
  {
    title: "user interaction",
    text: userInteraction
  },
  {
    title: "operators",
    text: operators
  },
  {
    title: "loops and labels",
    text: labellingLoops
  },
  {
    title: "function parameters: using default values",
    text: funcDefaultVals
  },
  {
    title: "destructuring, default values and rest operator",
    text: destructuring
  },
  {
    title: "object cloning",
    text: objectCloning
  },
  {
    title: "this keyword",
    text: thisKeyword
  }
]
const obj = { title, data, pages }

export default obj