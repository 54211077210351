const markdown = `
* can direct the input and output of a command to and from other files and programs
* these can be chained together in a pipeline

std (standard)
* \`stdin\` - information inputted into the terminal via the keyboard or input device
* \`stout\` - information outputted after a process is run
* \`stderr\` - error message outputting a failed process
* **redirection reroutes stdin, stdout and stderr to or from a different location**

| (pipe)
* a pipe takes stdout of a command on the left
* pipes it as the stdin command on the right
  * \`stdout | stdin\`

direction
* > -redirects stdout to a file
  * e.g \`echo "hello" > hello.txt\`
* >> - same as > but appends to the file on the right
* < - takes stdin from file on the right and inputs it into the program on the left
  * e.g. \`cat < lakes.txt\`

sort
* takes stdin, orders it alphabetically for stdout

uniq (unique)
* filters out adjacent, duplicate lines in a file
* note: duplicates that are not adjacent remain

grep (global regular expression print)
* searches files for lines that match a pattern then returns the results
* note: default is case-sensitive

options:
* -i enables commant to be case-insensitive
* -R searches all files in a directory, outputs all filenames and lines containing matched results
  * e.g grep -R Arctic /geography
* -RL searches all files in a directory outputs only filenames with matched results

sed (stream editor)
* similar to find and replace
* accepts stdin, modifies it based on an expression, displays it as output data
* e.g \`sed 's/snow/rain/g' forests.txt\`
`

export default markdown