import cmd from "./pages/cmd"
import cdn from "./pages/cdn"
import hostingTypes from "./pages/types"
import cloudHosting from "./pages/cloud-hosting"
import cloudflare from "./pages/cloudflare"
import servers from "./pages/servers"
import serverless from "./pages/serverless"

const title = 'Web Hosting'
const data = [
  {
    text: 'Content Management System (CMS)',
    url: 'https://en.wikipedia.org/wiki/Content_management_system',
    info: ''
  },
  {
    text: 'Content Delivery Network (CDN)',
    url: 'https://en.wikipedia.org/wiki/Content_delivery_network',
    info: ''
  }
]

const pages = [
  {
    title: "Web Hosting Types",
    text: hostingTypes
  },
  {
    title: "Cloud Hosting",
    text: cloudHosting
  },
  {
    title: "Cloudflare",
    text: cloudflare
  },
  {
    title: "Content Management System (CMS)",
    text: cmd
  },
  {
    title: "Content Delivery Network (CDN)",
    text: cdn
  },
  {
    title: "web servers",
    text: servers
  },
  {
    title: "serverless",
    text: serverless
  }
]


const obj = { title, data, pages }

export default obj