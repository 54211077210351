const markdown = `
Nginx
* powerful web server and uses a non-threaded, event-driven architecture
* outperform Apache if configured correctly. 
* can also do other important things:
  * load balancing, 
  * HTTP caching, 
  * or be used as a reverse proxy.
* https://nginx.org/

Apache
* free, open-source HTTP server
* available on many operating systems, but mainly used on Linux distributions. 
* one of the most popular options for web developers, as it accounts for over 30% of all the websites, as estimated by W3Techs.
* https://httpd.apache.org/
* https://www.youtube.com/watch?v=kaaenHXO4t4

Caddy
* an extensible, cross-platform, open-source web server written in Go. 
* has some really nice features like automatic SSL/HTTPs and a really easy configuration file.
* https://caddyserver.com/

MS IIS
* Internet Information Services (IIS) for Windows Server
* a flexible, secure and manageable Web server for hosting anything on the Web.
* https://www.iis.net/
`

export default markdown