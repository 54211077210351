const markdown = `
* in JS objects that can hold a lot of different elements
  * e.g \`var complexArr = [1, 5, "2", "Word", {"name": "James"}];\`
* in the world of high performance and different element types, **sometimes you need to be more specific on how much memory is given** to an array
* Typed arrays are the answer to this problem.
* You are now able to say how much memory you want to give an array.
  * Int8Array (1 byte), Uint8Array (1 byte), Uint8ClampedArray (1 byte)
  * Int16Array (2 bytes), Uint16Array (2 bytes)
  * Int32Array (4 bytes), Uint32Array (4 bytes), Float32Array (4 bytes)
  * Float64Array (8 bytes)

Method 1 (Directly)
\`\`\`
var i8 = new Int16Array(3);
console.log(i8); // logs [0, 0, 0]
\`\`\`

Method 2 (Using a Buffer)
\`\`\`
var byteSize = 6; // Needs to be multiple of 2
var buffer = new ArrayBuffer(byteSize);
var i8View = new Int16Array(buffer);
buffer.byteLength; // Returns 6
i8View.byteLength; // Returns 6
console.log(i8View); // logs [0, 0, 0]
\`\`\`
`

export default markdown