const markdown = `
* in JS, 'number' type cannot represent integer values:
  * larger than (253-1) (that’s 9007199254740991), or 
  * less than -(253-1) for negatives. 
* it's a technical limitation caused by their internal representation.

when you need big numbers
* sometimes we need really big numbers
* e.g. for cryptography or microsecond-precision timestamps.
* BigInt type was recently added to the language to represent integers of arbitrary length.
* a BigInt value is created by appending n to the end of an integer:
  * \`const bigInt = 1234567890123456789012345678901234567890n;\`
`

export default markdown