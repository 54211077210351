const markdown = `
Undo
* \`CTRL + U\`

Comment
* Add (Line): \`CTRL + K\` then \`CTRL + C\`
* Remove (Line): \`CTRL + K\` then \`CTRL + U\`
* Toggle
  * Line: \`CTRL + /\`
  * Block: \`SHIFT + ALT + A\`

Word
* Select: \`CTRL + D\`
* Go To:
  * Previous: \`CTRL + LEFT\`
  * Next: \`CTRL + RIGHT\`

Line
* Select: \`CTRL + L\`
* Go To:
  * Start: \`FN + HOME\`
  * End: \`FN + END\`
  * Specific: \`CTRL + G\`
  * In Another File: \`CMD PAL\` then type \`filename:line number\`
* Cut: \`CTRL + X\`
* Copy: \`CTRL + C\`
* Delete: \`CTRL + SHIFT + K\`
* Indent/Outdent: \`CTRL + ]\` / \`CTRL + [\`
* Move
  * Up: \`ALT + UP\`
  * Down: \`ALT + DOWN\`
* Paste
  * Up: \`ALT + UP\`
  * Down: \`ALT + DOWN\`
* Insert Empty Line
  * Up: \`SHIFT + ALT + UP\`
  * Down: \`SHIFT + ALT + DOWN\`

Bracket
* Jump To Matching: \`CTRL + SHIFT + /\`
* Fold
  * All: \`CTRL + K\` then \`CTRL + O\`
  * Region: \`CTRL + K\` then \`[\`
  * Sub-region: \`CTRL + SHIFT\` + \`[\`
* Unfold
  * All: \`CTRL + K\` then \`CTRL + J\`
  * Region: \`CTRL + K\` then \`]\`
  * Sub-region: \`CTRL + SHIFT\` + \`]\`

File
* Scroll (without moving cursor)
    * Up: \`CTRL + UP\`
    * Down: \`CTRL + DOWN\`
* Go To:
  * Start: \`CTRL + FN + HOME\`
  * End: \`CTRL + FN + END\`
  * Specific File: \`CTRL + P\`
* Word Wrap: \`ALT + Z\`
* Copy Active File Path: \`CTRL + K\` then \`P\`
* Reveal Active File (In Explorer): \`CTRL + K\` then \`R\`
`

export default markdown