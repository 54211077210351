const markdown = `
* Keyword 
  * \`p {color: red}\`
* Hex 
  * \`p {color: ##ff0000}\`
* Rgb 
  * \`p {color: rgb(255,0,0)}\`
* Rgba
  * \`p {color: rgba(255,0,0,0.5)}\`
* Hsl 
  * \`p {color: hsl(0,100%, 50%)}\`
* Hsla 
  * \`p {color: hsla(240, 100%, 50%, 0.5)}\`
* Opacity 
  * \`p {opacity: 0.5}\`
`

export default markdown