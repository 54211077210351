const markdown = `
* relational database = store data in a series of tables
* type of database that stores and provides access to data points that are related to one another. 

foreign keys
* interconnections between the tables are specified as foreign keys. 
* a foreign key is a unique reference from one row in a relational table to another row in a table
  * can be the same table but is most commonly a different table.

frameworks
* PostgreSQL
* MySQL
* MariaDB
* MS SQL
* Oracle
`

export default markdown