const markdown = `
open source projects use the following tools to organize discussion. (Reading through the archives will give you a good picture of how the community thinks and works.)

* issue tracker: where people discuss issues related to the project.
* pull requests: where people discuss and review changes that are in progress.
* discussion forums or mailing lists: 
  * some projects may use these channels for conversational topics
  *  (for example, "How do I…" or "What do you think about…" instead of bug reports or feature requests). 
  * others use the issue tracker for all conversations.
* synchronous chat channel: some projects use chat channels (such as Slack or IRC) for casual conversation, collaboration, and quick exchanges.
`

export default markdown