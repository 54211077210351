const markdown = `
### nullish coalescing operator
* for brevity, we will say that a value is "defined" when it's neither null nor undefined.
* the result of \`a ?? b\` is:
  * if a is defined, then a,
  * if a isn't defined, then b.
* in other words, \`??\` returns the first argument if it's not null/undefined. Otherwise, the second one.


question: isn't this the same as the \`||\` operator?
* the important difference  between \`||\` and \`??\` is that:
  * \`||\` returns the **first truthy value**.
  * \`??\` returns the **first defined value**.

### remainder
* operator \`%\`
* result of \`a % b\` is the remainder of the integer division of a by b.

### exponentiation
* operator \`a ** b\` raises a to the power of b.

## increment/decrement
* operators \`++\` and \`--\`can be placed either before or after a variable.

after the variable
* it is in **postfix form**: \`counter++\`
* postfix will return the previous value before the variable, 

after the variable
* it is in **prefix form**: \`++counter\`
* prefix will return the new value
* If we'd like to increase a value and immediately use the result of the operator, we need the prefix form:

makes no difference
* if the result of increment/decrement is not used, there is no difference in which form to use:
\`\`\`
let counter = 0;  
counter++;  
++counter;  
alert( counter ); // 2, the lines above did the same
\`\`\`
`

export default markdown