const markdown = `
Internet = global system of interconnected computer networks  

Is really two things
1. physical infrastructure
1. agreements, governance and technologies

### physical infrastructure
three basic parts:
1. consumer to telephone exchange
1. telephone exchange to datacenters/ internet exchange points (IEPS)
1. backbone = long distance networks (massive fibre optic cables)

### agreements, governance and technologies
agreements
* no one runs the internet - organised as a decentralised network of networks
* thousands of companies, universities, governments, and other entities operate their own networks
* they exchange traffic with each other based on voluntary interconnection agreements. (I think these are called peering agreements)

governance and technologies
* Internet Engineering Task Force (IEFT) - responsponsible for the shared technical standards (Internet Protocol Suite (TCP/IP)) that make the internet work 
* Internet Corporation for Assigned Names and Numbers (ICANN) - responsponsible for IP addresses and domain names
`

export default markdown