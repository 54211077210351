const markdown = `
### array
destructure
* e.g. \`let [firstName, , title] = ["Julius", "Caesar", "Consul", "of the Roman Republic"];\`
* trick: quick swap
  * \`let guest = "Jane"; let admin = "Pete";\`
  * \`[guest, admin] = [admin, guest];\`

default values
* e.g. \`let [name = "Guest", surname = "Anonymous"] = ["Julius"];\`

rest operator
* e.g. \`let [name1, name2, ...rest] = ["Julius", "Caesar", "Consul", "of the Roman Republic"];\`

### object
* e.g. \`const { fName, lName } = person\`
* reassigning prop names:
  * \`const { fName: firstName, lName: lastName } = person\`

default values
* e.g. \`let {name = "Guest", surname = "Anonymous"} = person\`

rest operator
* e.g. \`let {title , ...rest} = person\`
`

export default markdown