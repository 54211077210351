import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import data from "./data/index"
import App from './App';
import Main from './pages/Main';
import Section from './pages/Section';
import Page from './pages/Page';
import getFName from './func/getFName';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='/' element={<Main data={data}/>} />
          { getSectionRoutes(data.slice(1))}
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

function getSectionRoutes(data) {
  return data.map((d, i) => {
    const path = `/${getFName(d.title)}`
    const routes = [
      <Route path={path} element={<Section data={d}/>} />,
      <Route path={path.concat('/overview')} element={<Page title={d.title} body={d.overview}/>} />,
      getSectionPageRoutes(d.pages, path)
    ].filter(r => r).flat()
    return (
      <>
        { routes }
      </>
    )
  })
}

function getSectionPageRoutes(pages, path) {
  if(!pages) return null
  return pages.map((p, i) => {
    const pagePath = path.concat(`/${getFName(p.title)}`)
    return <Route path={pagePath} element={<Page title={p.title} body={p.text}/>} />
  })
} 



reportWebVitals();
