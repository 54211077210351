const title = 'General'
const data = [
  {
    text: 'DevDocs API documentation',
    url: 'https://devdocs.io/',
    info: 'A wonderful reference for quickly looking up features quickly'
  },
  {
    text: 'Developer Roadmaps',
    url: 'https://roadmap.sh/',
    info: 'A very useful guide'
  },
  {
    text: 'Guidance from Chrome Developer Relations',
    url: 'https://web.dev/',
    info: 'building modern web experiences that work on any browser'
  },
  {
    text: 'Markdown Cheat Sheet',
    url: 'https://www.markdownguide.org/cheat-sheet/',
    info: ''
  }
]

const obj = { title, data }

export default obj