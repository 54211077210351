const markdown = `
what is an origin?
* web content's origin = defined by the scheme (protocol), hostname (domain), and port of the URL used to access it
* Two URLs have the same origin if they share the same:
  *  protocol: e.g. http://news.company.com/dir/page.html i.e **http://**
  * port (if specified): e.g. http://store.company.com:81/dir/page.html i.e **:81**
  * host are the same for both: e.g. http://store.company.com:81/dir/page.html i.e **store.company.com**

same origin policy
* critical security mechanism
* restricts how a document or script loaded by one origin can interact with a resource from another origin.

why
* helps isolate potentially malicious documents, reducing possible attack vectors.
* example, prevents a malicious website on the Internet from running JS in a browser to read data from a third-party webmail service (which the user is signed into)
`
export default markdown