const markdown = `
* Cross-Origin Resource Sharing
* a HTTP-header based mechanism
* allows a server to indicate any origins (domain, scheme, or port) other than its own from which a browser should permit loading resources

example of a cross-origin request
* the front-end JS code served from *https://domain-a.com* uses XMLHttpRequest to make a request for *https://domain-b.com/data.json*
* for security reasons, browsers restrict cross-origin HTTP requests initiated from scripts.
* for example, XMLHttpRequest and the Fetch API follow the same-origin policy (SOP).
* means that a web app using those APIs can only request resources from the same origin the application was loaded from 
  * **unless the response from other origins includes the right CORS headers**.

preflight
* CORS also relies on another mechanism
* by which browsers make a "preflight" request to the server hosting the cross-origin resource
  * browser sends headers that indicate the HTTP method and headers that will be used in the actual request
* does this to check that the server will permit the actual request. 
`

export default markdown