const markdown = `
alert
* shows a message and waits for the user to press “OK”.

prompt
* visitor can type something in the prompt input field and press OK. 
* then we get that text in the result. 
* or they can cancel the input by pressing cancel or hitting the Esc key, then we get null as the result.

confirm
- shows a modal window with a question and two buttons: OK and Cancel.
- the result is true if OK is pressed and false otherwise.
`

export default markdown