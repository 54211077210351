const markdown = `
* Maps are data structures that store key-value pairs. 
* In JS, these are available to us as objects. 
* provide rapid lookup of stored items based on key values.
~~~js
var Map = function() {
  this.collection = {};
  // change code below this line
  this.add = function(key, value){
    this.collection[key] = value;
  };
  this.remove = function(key){
    delete this.collection[key]
  };
  this.get = function(key){
    return this.collection[key]
  };
  this.has = function(key){
    return this.collection.hasOwnProperty(key)
  };
  this.values = function(){
    return Object.values(this.collection)
  };
  this.size = function(){
    return Object.keys(this.collection).length
  };
  this.clear = function(){
    this.collection = {}
  };
  // change code above this line
};
~~~

map object
* although similar to regular JavaScript objects, 
* provides some useful functionality that normal objects lack
~~~js
let myMap = new Map()
myMap.set('freeCodeCamp', 'Awesome!')
~~~
`

export default markdown