const title = 'Testing & Performance'
const data = [
  {
    text: "What Is Software Testing?",
    url: 'https://www.softwaretestingmaterial.com/software-testing/',
    info: ''
  },
  {
    text: "Different types of software testing",
    url: 'https://www.atlassian.com/continuous-delivery/software-testing/types-of-software-testing',
    info: ''
  },
  {
    text: "Testing: Cypress ",
    url: 'https://www.cypress.io/',
    info: ''
  },
  {
    text: "Performance: Techniques",
    url: 'https://web.dev/fast/',
    info: ''
  },
  {
    text: "Performance: Lighthouse",
    url: 'https://web.dev/lighthouse-pwa/',
    info: ''
  },
  {
    text: "Performance: Lighthouse: Intro",
    url: 'https://www.freecodecamp.org/news/introduction-to-chrome-lighthouse/',
    info: ''
  },
  {
    text: "Performance: RAIL Model",
    url: 'https://web.dev/rail/',
    info: ''
  },
  {
    text: "Performance: PRPL Pattern",
    url: 'https://www.patterns.dev/posts/prpl/',
    info: ''
  },
  {
    text: "Performance: Progressive Web Apps",
    url: 'https://web.dev/learn/pwa/',
    info: ''
  },
  {
    text: "Performance: Speed Up A Website",
    url: 'https://www.cloudflare.com/learning/performance/speed-up-a-website/',
    info: ''
  }
]

const obj = { title, data }

export default obj