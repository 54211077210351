import workFlow from './pages/work-flow'
import basicCommands from './pages/basic-commands'
import projStruc from "./pages/proj-struc"

const title = 'Git'
const data = [
  {
    text: 'Official Docs',
    url: 'https://git-scm.com/docs',
    info: ''
  }
]

const pages = [
  {
    title: 'workflow (basic)',
    text: workFlow
  },
  {
    title: 'commands (basic)',
    text: basicCommands
  },
  {
    title: 'project structure',
    text: projStruc
  }
]

const obj = { title, data, pages }

export default obj