const markdown = `
* open code with current directory
  * \`code .\`
* open the current directory in the most recently used code window
  * \`code -r .\`
* create a new window
  * \`code -n\`
* change the language
  * \`code --locale=es\`
* open diff editor
  * \`code --diff <file1> <file2>\`
* open file at specific line and column <file:line[:character]>
  * \`code --goto package.json:10:5\`
* see help options
  * \`code --help\`
* disable all extensions
  * \`code --disable-extensions .\`
`

export default markdown