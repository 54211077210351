const markdown = `
* VSC's control center
* \`CTRL + SHIFT + P\`
* Type:
  * eg. 'go to file', 'new window', 'file:save'
  * Typing commands such as 'edt' and 'term' followed by a space will bring up dropdown lists

Commands
* Suggestion: type \`?\`
* Quick Open: \`CTRL + P\`
* Recent Open: \`CTRL + R\`
* Text Transforms: type \`transform\`

Keyboard Shortcuts
* Edit a Shortcut
  * bring up command on command palette
  * click on gear icon (will be on the right hand side)
* Access All Shortcuts: \`CTRL + K then CTRL + S\`
  * (or file menu => preferences => keyboard shortcuts)
  * JSON File
    * Default Shortcuts: type 'preferences: open default keyboard shortcuts (json)'
    * Custom Shortcuts: type 'preferences: open keyboard shortcuts (json)'
`

export default markdown