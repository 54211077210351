const markdown = `
CLI is a:
* text interface for your computer
* program that takes commands => passes them onto computer's OS to run
* $ (shell prompt) - appears when the terminal is ready to accept a command
* commands sometimes have options which modify the behaviour of the command

File System (FS)
* organises a computer's files and directories into a tree structure
* also: folders are referred to as directories when using the command line
* 1st Directory in a FS = root directory

Basics
* \`pwd\` - print working directory
* \`cd [directory name]\` - change directory
* \`cd ..\` - move up one directory
* \`mkdir [new directory name]\` - make directory
* \`touch [new filename]\` - create file
* \`cat [filename]\` - outputs file content to terminal

flags
* wildcards
  * besides using filenames as arguments you can also use special characters:
    * *- selects all files in the working directory
    * *m - selects all files beginning with m in the workind directory
* ls
  * lists files in the current working directory
  * options:
    * -a lists all files and directories strating with a dot i.e hidden files
    * -l lists all content of a directory in long format
    * -t orders files (and directories) by the time they were last modified
    * -alt combines ls-a, ls-l and ls-t

cp
* copy files or directories
* syntax: cp [what to copy] [destination]
* examples:
  * \`cp frida.txt linxoln.txt\` (copies content of frida.txt to lincoln.txt)
  * \`cp biopic/cleopatra.txt historical\` (copies 1st argument into target directory)
  * \`cp bipic/ray.txt biopic/notorious.txt historical\` (copies 1st and 2nd arguments to target directory)

mv
* move files (works like cp)
* syntax: \`mv [what to move] [destination]\`
* examples:
  * \`mv superman.txt superhero/\`
  * \`mv batman.txt spiderman.txt\` (rename file)

rm
* permanantly removes files (or directories)
* syntax: \`rm [what to delete]\`
* examples:
  * \`rm waterboy.txt/\` (remove file)
  * \`rm -r comdey\` (remove directory)
    * -r stands for recursively - deletes named directories and child directories
`

export default markdown