import basics from "./pages/basics"
import gatsby from "./pages/gatsby"

const title = 'Static Site Generators'
const data = [
  {
    text: "What is it?",
    url: 'https://www.cloudflare.com/learning/performance/static-site-generator/',
    info: ''
  },
  {
    text: "Basics",
    url: 'https://thenewstack.io/get-back-basics-static-website-generators/',
    info: ''
  },
  {
    text: 'Gatsby',
    url: 'https://www.gatsbyjs.com/docs/glossary/static-site-generator/',
    info: ''
  },
  {
    text: 'Gatsby: How To',
    url: 'https://www.gatsbyjs.com/docs/how-to/',
    info: ''
  }
]

const pages = [
  {
    title: 'Basics',
    text: basics
  },
  {
    title: 'Gatsby',
    text: gatsby
  }
]

const obj = { title, data, pages }

export default obj