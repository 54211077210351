const markdown = `
shared hosting
* provider houses multiple sites on a single server
* e.g. site A shares the same server with Site B, Site C, Site D, and Site E
* upside = multiple sites share the server cost, so generally very inexpensive (less than $10 per month)
* in housing terms (analogy):
  * could think of the sites that share your server as your roommates; 
  * there's really not that much separating you from them. 
  * sure, you can close the bedroom door, but they can still cause nightmares for you in the kitchen and the bathroom. 
* in web hosting terms:
  * all the sites share a single server's resources
  * so huge traffic spikes on Site A may impact the neighboring sites' performances. 
  * even possible that another site could take down the shared server altogether, if it crashed hard enough.

vps hosting 
* similar to shared hosting: multiple sites share the same server, but the similarities end there
* in housing terms (analogy), 
  * vps hosting is like renting your own apartment in a larger building.
  * you're much more isolated than in the roommate situation mentioned above;
  * still possible that a neighboring apartment could causes annoyance for you, but far less likely. 
* in web hosting terms
  * Site A's traffic surge won't have nearly as much impact on Site B or Site C. 
  * vps hosting costs more than shared hosting. (roughly $20 to $60 per month)

dedicated web hosting
* both powerful and pricey.
* reserved for sites that require an incredible amount of server resources
* unlike shared or VPS hosting, dedicated hosting makes your website the lone tenant on a server.
* in housing terms (analogy) 
  * having a dedicated server is like owning your own home
  * your website taps the server's full power, and pays for the privilege. 
  * if you're looking for a high-powered site— an online mansion for your business—dedicated hosting is the way to go. 
* caveat: many dedicated web hosting services task you with handling backend, technical issues, much as homeowners have managed maintenance that renters generally leave to their landlords.
* managed hosting
  * many web hosting services also offer managed hosting. 
  * web host act as your IT department, handling a server's maintenance and upkeep.
  * this hosting option is something that you'd typically find with dedicated servers, so it's a business-centric addition.
  * naturally, adds a few bucks to the hosting cost, but nothing that should break the bank if you have the resources for a dedicated server.

WordPress Web Hosting
* for people who want to build their sites on the back of the popular WordPress content management system (CMS) from WordPress.org.
* multiple ways to set up shop using this free, open-source blogging and site-building platform.
* options
  * self-hosted site
    * gain the most web-building functionality if you create a self-hosted site. 
    * typically involves transferring the free WordPress CMS to a server or signing up for a web host's optimized WordPress plan. 
    * with an optimized plan, the host automatically handles backend stuff, so you don't have to worry about updating the plug-ins and CMS, and enabling automatic backups. 
    * in these instances, the WordPress environment typically comes pre-installed on the server.
  * WordPress hosted site
    * can also host your website on WordPress.com, but that's different from the kind of hosting mentioned above.
    * WordPress.com uses the same code from WordPress.org, but it hides the server code and handles the hosting for you. 
    * simpler but less flexible and customizable way to approach WordPress hosting. 
    * definitely easier, but if you want to tinker and adjust and optimize every aspect of your site, it might not be for you.

`

export default markdown

