const markdown = `
Step 1: Choose a Name - [naming guidelines](https://docs.npmjs.com/package-name-guidelines) 

Step 2: Create Node.js module
* [three steps](https://docs.npmjs.com/creating-node-js-modules)
  * create a \`package.json\` file
    * [specifying dependencies and devDependencies in a package.json file](https://docs.npmjs.com/specifying-dependencies-and-devdependencies-in-a-package-json-file)
  * create the file that will be loaded when your module is required by another application
  * [test your module](https://docs.npmjs.com/creating-node-js-modules#test-your-module)

Step 3: [Add a README.md file to a package](https://docs.npmjs.com/about-package-readme-files)

Step 4: Publishing
* need an npm user account
* types:
  * [public: scoped](https://docs.npmjs.com/creating-and-publishing-scoped-public-packages)
  * [public: unscoped](https://docs.npmjs.com/creating-and-publishing-unscoped-public-packages)
  * [private (has to be scoped)](https://docs.npmjs.com/creating-and-publishing-private-packages) - paid accounts only

note: add [dist tags](https://docs.npmjs.com/adding-dist-tags-to-packages) when pubishing

[updating packages](https://docs.npmjs.com/packages-and-modules/updating-and-managing-your-published-packages)
`

export default markdown