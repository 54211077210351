import basics from "./pages/basics"
import units from "./pages/units"
import colors from "./pages/colors"
import positionProp from "./pages/position-prop"
import transformProp from "./pages/transform-prop"
import grid from "./pages/grid"
import flexBoxAlignment from "./pages/flexbox-alignment"
import flexbox from "./pages/flexbox"
import mediaQueries from "./pages/media-queries"
import animations from "./pages/animations"
import atRules from "./pages/at-rules"

const title = 'CSS'
const data = [
  {
    text: 'css entities',
    url: 'https://www.w3schools.com/cssref/css_entities.asp',
    info: ''
  },
  {
    text: 'Bootstrap 5',
    url: 'https://getbootstrap.com/docs/5.0/getting-started/introduction/',
    info: ''
  },
  {
    text: 'Tailwind CSS',
    url: 'https://tailwindcss.com/',
    info: ''
  },
  {
    text: 'Tailwind CSS: Intro',
    url: 'https://www.codemag.com/Article/2105091/Tailwind-CSS-An-Introduction',
    info: ''
  },
  {
    text: 'Google Fonts',
    url: 'https://fonts.google.com/',
    info: ''
  }
]

const pages = [
  {
    title: "basics",
    text: basics
  },
  {
    title: "units",
    text: units
  },
  {
    title: "colours",
    text: colors
  },
  {
    title: "property: position & offset",
    text: positionProp
  },
  {
    title: "property: transform",
    text: transformProp
  },
  {
    title: "grid",
    text: grid
  },
  {
    title: "flexbox: understanding alignment",
    text: flexBoxAlignment
  },
  {
    title: "flexbox: basics",
    text: flexbox
  },
  {
    title: "@ rules",
    text: atRules
  },
  {
    title: "@media",
    text: mediaQueries
  },
  {
    title: "animations",
    text: animations
  }
]

const obj = { title, data, pages }

export default obj