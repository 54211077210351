import https from "./pages/https"
import sop from "./pages/sop"
import cors from "./pages/cors"
import csp from "./pages/csp"

const title = 'Web Security'
const data = [
  {
    text: 'privacy tools',
    url: 'https://www.privacytools.io/',
    info: ''
  },
  {
    text: 'privacy guides',
    url: 'https://www.privacyguides.org/',
    info: ''
  },
  {
    text: 'Stanford Uni course',
    url: 'https://web.stanford.edu/class/cs253/',
    info: ''
  },
  {
    text: 'Web App Security Testing Checklist',
    url: 'https://github.com/0xRadi/OWASP-Web-Checklist',
    info: ''
  },
  {
    text: 'Top Risks & Vulnerabilities 2021',
    url: 'https://sucuri.net/guides/owasp_top_10_2021_edition/',
    info: ''
  },
  {
    text: 'HTTPS: Enabling It On Your Servers',
    url: 'https://web.dev/enabling-https-on-your-servers/',
    info: ''
  },
  {
    text: 'AJAX Security Cheat Sheet',
    url: 'https://cheatsheetseries.owasp.org/cheatsheets/AJAX_Security_Cheat_Sheet.html',
    info: ''
  },
  {
    text: 'Content Security Policy (CSP)',
    url: 'https://web.dev/csp/',
    info: ''
  },
  {
    text: 'Cross-Origin Resource Sharing (CORS)',
    url: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS',
    info: ''
  }
]

const pages = [
  {
    title: 'HTTPS',
    text: https
  },
  {
    title: 'SOP: Same Origin Policy',
    text: sop
  },
  {
    title: 'CORS: Cross-Origin Resource Sharing',
    text: cors
  },
  {
    title: 'CSP: Content Security Policy',
    text: csp
  }
]

const obj = { title, data, pages }

export default obj