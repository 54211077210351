const markdown = `
position property values
* static
  * element falls into natural flow of the page
  * default
  * non position value
* fixed
  * element positioned **in relation to the browser**
  * taken out of the flow of the page
  * ignores scrolling
* absolute
  * element positioned in **relation to parent element**
  * taken out of the flow of the page
  * parent must be fixed, absolute or relative
* relative
  * element positioned **relative to where it would normally be**
  * reserves the space where it would have been
  * great companion to absolute

offset property
* values: top, right, down, left
* tells browser how far to offset an item relative to where it would sit in the normal flow of the document. 
* you're offsetting an element away from a given spot: away from the referenced side (effectively, the opposite direction). 
  * i.e using the top offset actually moves the element down
`

export default markdown