const markdown = `
shared hosting
- servers with multiple websites on them. 
- web hosting companies build and maintain these servers, and they place a bunch of websites on them. 
- means if your website is hosted on a shared server, it's vying for the same resources as many other websites. 

cloud hosting
- a service that exists on multiple servers. 
- instead of on one shared server, your site is hosted in the cloud. 
- means the company can bounce your website hosting between servers as it adjusts to performance spikes and other stresses. 
- more dynamic than shared hosting, and this means it can perform better and be more secure than some shared hosting services.
`

export default markdown