const markdown = `
![alt text](/images/flex_terms.png)
* make element a flexbox = \`display:flex\`
* the default flex-direction will be 'rows'
* in this scenario
  * the main axis is horizontal
  * the cross axis is vertical
* if we make the flex-direction value 'columns'
  * the main axis becomes vertical
  * the cross axis becomes horizontal

why does this matter?
* must know the direction of the main axis to choose how to align the items in the flexbox
* if **main axis is horizontal (i.e. rows)**
  * for items use justify-content property
  * for an item use justify-self property
* if **main axis is vertical (i.e. columns)**
  * for items use align-items property
  * for an item use align-self property
`

export default markdown