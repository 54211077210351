const markdown = `
\`\`\`
function Stack() {
  var collection = [];
  this.print = function() {
    console.log(collection);
  };
  // Only change code below this line
  this.peek = function() {
    return collection[0]
  };
  this.isEmpty = function() {
    return collection.length === 0
  }
  this.clear = function() {
    collection = []
  }
  this.pop = function() {
    return collection.pop()
  }
  this.push = function(e) {
    return collection.push(e)
  }
  // Only change code above this line
}
\`\`\`
`

export default markdown