import List from "../comps/List"
import ListPages from "../comps/ListPages"
import getFName from "../func/getFName"

export default function Section({ data }) {
  const path = `/${getFName(data.title)}`
  const listPages = data.pages ? <ListPages path={path} pages={data.pages}/> : ''
  return (
    <div>
      <List list={data}/>
      { listPages }
    </div>
  )
}