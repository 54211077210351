const markdown = `
* simply a network of servers used to deliver content
* a geographically distributed network of proxy servers and their data centers
* goal = provide high availability & performance by distributing the service spatially relative to end users.
* **main benefit: CDN provides a platform for delivering large amounts of content closer to the end user**


how it works
* one or more servers are designated as "origin" servers, 
* and other servers are distributed throughout various global locations as "cache" servers. 
* these caches are strategically located to be geographically proximate to various end users: the audience. 
* the content or source media is stored on the origin server(s) and then sent out to cache servers as needed.
* So:
  * when a user requests a resource or content
  * a special CDN URL is resolved against Domain Name Service (DNS) into an IP address to call the content from a cache server that is closer to the requesting user than the origin server. 
  * this increases the speed at which the content is delivered to end users by decreasing the distance the information must travel and thus reducing latency.
  * also reduces the stress on the main server(s) by distributing the load across multiple servers in different regions.

when is it used?
* primarily used to deliver **large-file content that is often static**: video, music and images are all common. 
* increasingly being used for streaming media as well.
* use case: an organization that delivers a streaming video content update on a weekly basis to thousands of users distributed across the U.S. might make good use of a CDN.
* contrast with traditional delivery:  where all users connect to a centralized server.
  * in this model, the user experience will vary based on a number of factors like their distance from the server.
  * users accessing the streaming video from the other side of the country might see slow video load times and buffering issues due to higher latencies. 
  * and all users might experience delivery issues should the centralized server hit user connection limits or other resource consumption issues.
* CDNs solve these issues by delivering the streaming media to local cache servers
* reduces the load on the central or origin server—lowering the likelihood of overload
* ensures that latency between the media and the users stays lower.

cache
* in computing, a cache is a high-speed data storage layer
* stores a subset of data, typically transient in nature. 
* so that future requests for that data are served up faster than is possible by accessing the data's primary storage location.
* caching allows you to efficiently reuse previously retrieved or computed data.
`

export default markdown