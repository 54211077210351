const markdown = `
* smart code completions
* suggestions widget: \`CTRL + SPACE\`

Peek (\`esc\` to exit peek)
* Select Symbol (Definition) then \`ALT + F12\`
* Or, use the context menu (right click)

Definition
* Goto
  * Select Symbol (Definition) then press \`F12\`
  * Or, use the context menu (right click)
  * In Another File: type \`filename@symbol name\`
* Go Back: \`ALT + LEFT\`
`

export default markdown