import what from "./pages/what"
import tcpIp from "./pages/tcp-ip"
import dns from "./pages/dns"
import url from "./pages/url"
import domainName from "./pages/domain-name"

const title = 'Internet'
const data = [
  {
    text: 'What is DNS?',
    url: 'https://www.cloudflare.com/en-gb/learning/dns/what-is-dns/',
    info: ''
  },
  {
    text: 'DNS records?',
    url: 'https://www.cloudflare.com/en-gb/learning/dns/dns-records/',
    info: ''
  },
  {
    text: 'tool: DNS resolution in action',
    url: 'https://simpledns.plus/lookup-dg',
    info: 'shows every step of the process'
  },
  {
    text: 'tool: experiment with dns',
    url: 'https://messwithdns.net/',
    info: 'dns playground'
  }
]

const pages = [
  {
    title: 'what is it?',
    text: what
  },
  {
    title: 'TCP/IP: Internet protocol suite',
    text: tcpIp
  },
  {
    title: 'DNS: domain name system',
    text: dns
  },
  {
    title: 'domain name',
    text: domainName
  },
  {
    title: 'URL: uniform resource locater',
    text: url
  }
]

const obj = { title, data, pages }

export default obj