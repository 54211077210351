const markdown = `
ignoring
* *.gitignore* file: files that you don't want Git to automatically add or even show you as being untracked

setting up
* \`git init\` - sets up git for your project
* \`git clone [url] [rename]\` - clone the repository to your local machine
* \`git pull origin master\` - make sure you have the most upto date clone
* \`git fetch\` - not the same as pull as it only retrieves the latest meta-data and doesn't update any local files

basics
* \`git status\` - check status of changes (files in red are untracked)
* \`git log\` chronological list of past commits
* \`git add [file name]\` - place file into the staging area and if untracked begin tracking it
* \`git diff [file name]\` - show difference between file in the working directory and staging area
* \`git commit -m "valuable_but_short_message_here"\` - permanently stores code from staging to the local repository

pushing code
* \`git push\` - push code to github


going Back
* sometimes we make changes that we want to get rid of
* 'head commit' is your latest git commit
  * \`git reset HEAD [filename]\` - unstage a file from staging area
  * \`git checkout HEAD [filename]\` - to restore the file you are currently working on to exactly as it was when you last commited
  * \`git reset commit [_SHA]\` - go back to a previous commit

branching
* git allows you to create branches to experiment with different versions of your project
  * \`git branch\` - current branch
  * \`git branch [new branch]\` - create a branch
  * \`git branch -d [branch name]\` - deleting a branch
  * \`git checkout [branch name]\` - check out another branch

merging branches
* \`git merge [branch name]\` - when on the master branch
`

export default markdown