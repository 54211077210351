const markdown = `
consists of three distinct pieces:
* website
* registry
* cli

npm database
* developers create a small building block of code that solves one problem well
* then "package" the code into a directory that follows npm guidelines
* npm has a whole database of these packages ready to use

packages and modules
* package = file or directory that is described by a package.json
* module = any file or directory that can be loaded by Node.js' require()

Semver (Semantic Versioning)
* [major].[minor].[patch]
* major = changes that break backward compatability
* minor = new features that don't break existing features
* patch = bug fixes, other minor changes
* [about semantic versioning](https://docs.npmjs.com/about-semantic-versioning)
* [semver calculator](https://semver.npmjs.com/)
`

export default markdown