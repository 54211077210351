const markdown = `
## container
* set to flex container = \`display: flex\`

flex-direction
* default value = rows
  * \`flex-direction: row;\` will align the children horizontally
  * \`flex-direction: column;\` will align the children vertically

flex-wrap
* wraps item - means extra items move into a new row or column
* by default, a flex container will fit all flex items together (eg. row will all be on one line)
* break point of where the wrapping happens depends on the size of the items and the size of the container.
* values: nowrap, wrap, wrap-reverse

## items
* several useful properties for the flex items

order
* order of how flex items appear in the flex container. 
* by default, items appear in the same order they come in the source HTML. 
* property takes numbers as values, and negative numbers can be used.

flex-shrink
* allows an item to shrink if the flex container is too small.
* items shrink when the width of the parent container is smaller than the combined widths of all the flex items within it.
* property takes numbers as values. 
* higher the number, the more it will shrink compared to the other items in the container. 
  * e.g. if one item has a flex-shrink value of 1 and the other has a flex-shrink value of 3
  * the one with the value of 3 will shrink three times as much as the other

flex-grow
* opposite of flex-shrink. 
* property controls the size of items when the parent container expands

flex-basis
* specifies the initial size of the item before CSS makes adjustments with flex-shrink or flex-grow
* The units used by the flex-basis property are the same as other size properties (px, em, %, etc.). 
* The value auto sizes items based on the content

flex
* shorthand for flex-grow, flex-shrink, and flex-basis
  * e.g flex: 1 0 10px;
  * will set the item to:
    * flex-grow: 1;
    * flex-shrink: 0;
    * flex-basis: 10px
* default property settings are \`flex: 0 1 auto;\`
`

export default markdown