import overview from "./overview"
import cli from "./pages/cli"
import vscFolder from "./pages/vsc-folder"
import debugging from "./pages/debugging"
import sourceControl from "./pages/source-control"
import ksDisplay from "./pages/ks-display"
import ksEditing from "./pages/ks-editing"
import ksMsSelection from "./pages/ks-multicursor-selection"
import KsFileManagement from "./pages/ks-file-mangement"
import ksNavigation from "./pages/ks-navigation"
import ksCommandPalette from "./pages/ks-command-palette"
import ksIntellisense from "./pages/ks-intellisense"


const title = 'Visual Studio Code'
const data = [
  {
    text: 'Official Docs',
    url: 'https://code.visualstudio.com/docs/',
    info: ''
  },
  {
    text: 'All Keyboard Shortcuts',
    url: 'https://code.visualstudio.com/shortcuts/keyboard-shortcuts-windows.pdf',
    info: 'PDF'
  },
  {
    text: 'Integrated Terminal Docs',
    url: 'https://code.visualstudio.com/docs/editor/integrated-terminal',
    info: ''
  },
  {
    text: 'Debugging Docs',
    url: 'https://code.visualstudio.com/docs/editor/debugging',
    info: ''
  },
  {
    text: 'VSC tasks',
    url: 'https://code.visualstudio.com/docs/editor/tasks',
    info: ''
  }
]

const pages = [
  {
    title: 'VSC: Command Line',
    text: cli
  },
  {
    title: '.vscode folder',
    text: vscFolder
  },
  {
    title: 'Debugging',
    text: debugging
  },
  {
    title: 'Source Control (git)',
    text: sourceControl
  },
  {
    title: 'KS: Display',
    text: ksDisplay
  },
  {
    title: 'KS: Command Palette',
    text: ksCommandPalette
  },
  {
    title: 'KS: Navigation',
    text: ksNavigation
  },
  {
    title: 'KS: File Management',
    text: KsFileManagement
  },
  {
    title: 'KS: Editing',
    text: ksEditing
  },
  {
    title: 'KS: Multi-Cursor Selection',
    text: ksMsSelection
  },
  {
    title: 'KS: Intellisense',
    text: ksIntellisense
  }
]

const obj = { title, data, pages, overview }

export default obj