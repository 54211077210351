const markdown = `
* package = file or directory that is described by a package.json file.
* package must contain a package.json file in order to be published to the npm registry. 
* packages can be
  * unscoped or scoped to a user or organization, and 
  * scoped packages can be private or public.

scopes
* when you sign up for an npm user account or create an organization, you are granted a scope that matches your user or organization name.
* you can use this scope as a namespace for related packages.
* scope **allows you to create a package with the same name as a package created by another user or organization without conflict**.
* when listed as a dependent in a package.json file, scoped packages are preceded by their scope name. 
  * scope name is everything between the @ and the slash:
  * \`@npm/package-name\`

scopes and package visibility
* unscoped packages are always public.
* private packages are always scoped.
* scoped packages are private by default; you must pass a command-line flag when publishing to make them public.

[package formats](https://docs.npmjs.com/about-packages-and-modules#about-package-formats)
`

export default markdown