const markdown = `
* specify the so-called 'default' (to use if omitted) value for a parameter in the function declaration, using \`=\`:

example 1  
\`\`\`
function showMessage(from, text = "no text given") { alert( from + ": " + text ); }  
showMessage("Ann"); // Ann: no text given
\`\`\`

example 2  
\`\`\`
function showMessage(from, text = anotherFunction()) {  
  // anotherFunction() only executed if no text given  
  // its result becomes the value of text  
}  
\`\`\`
`

export default markdown