import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
// import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'

export default function Page({title, body}) {
  const noOverview = <p>No Overview Available :(</p>

  const componenets = {
    code({node, inline, className, children, ...props}) {
      const match = /language-(\w+)/.exec(className || '')
      return !inline && match ? (
        <SyntaxHighlighter
          children={String(children).replace(/\n$/, '')}
          language={match[1]}
          PreTag="div"
          {...props}
        />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      )
    }
  }

  const content = body ? <ReactMarkdown children={body} remarkPlugins={[remarkGfm]} components={componenets}/> : noOverview
  return (
    <div>
      <h1>{title}</h1>
      { content }
    </div>
  )
}