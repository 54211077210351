const markdown = `
* tool = generates a full static HTML website based on raw data and a set of templates. 
* automates the task of coding individual HTML pages and gets those pages ready to serve to users ahead of time.
  * HTML pages are pre-built => load very quickly in users' browsers.

more
* most static SSGs, including Gatsby, accept Markdown-formatted text files as a source, although Gatsby is not limited to Markdown.

Database-driven content management systems Process
* SSGs are an alternative to database-driven content management systems, such as WordPress and Drupal. 
  * In such systems, content is managed and stored in a database. 
  * When the server receives a request for a particular URL, 
  * a software layer retrieves data from the database, 
  * merges it with template files, 
  * and generates an HTML page as its response.

SSG Process
* SSGs, on the other hand, generate HTML pages during a build process. 
  * Gatsby, for example, 
  * loads JSON from GraphQL, 
  * and merges that data with components to create HTML pages. 
  * These generated pages are then deployed to a web server. 
  * When the server receives a request, it responds with rendered HTML. 
  * Static pages eliminate the latency that databases introduce.

Note: It’s also possible to use Gatsby without GraphQL, using the createPages API.

JAMStack
* You can also use static site generators to create JAMStack sites. 
* JAMStack is a modern website architecture
* uses JavaScript, content APIs, and markup. 
* Gatsby, for example, can use the WordPress REST API as a data source.

Advantages of static site generators
* SSGs reduce site complexity. 
* That, in turn, improves speed and reliability, and smooths the developer experience.
  * You don't have to worry about database-toppling traffic spikes.
  * No need to manage database server software or backups.
  * You can use version control software to manage and track changes to your content.
  * Because your site is static, you can even forgo web servers and load balancers altogether. 
    * Instead, you can host your site with a content delivery network that scales with your site's traffic.
  * There are dozens of static site generators available, created with a range of programming languages. 
    * Gatsby is JavaScript at its core, and is built with React, GraphQL, and Node.js. 
    * See how Gatsby compares to WordPress and Drupal or to popular static site generators.
`

export default markdown