const title = 'ReactJS'
const data = [
  {
    text: 'create react app',
    url: 'https://reactjs.org/docs/create-a-new-react-app.html',
    info: ''
  },
  {
    text: 'Official Docs',
    url: 'https://reactjs.org/docs/getting-started.html',
    info: ''
  },
  {
    text: 'css modules: general',
    url: 'https://github.com/css-modules/css-modules',
    info: ''
  },
  {
    text: 'css modules: in react',
    url: 'https://medium.com/@ralph1786/using-css-modules-in-react-app-c2079eadbb87',
    info: ''
  },
  {
    text: 'react router docs',
    url: 'https://reactrouter.com/en/main',
    info: ''
  },
  {
    text: 'lib: react bootstrap',
    url: 'https://react-bootstrap.github.io/components/alerts',
    info: ''
  },
  {
    text: 'lib: Radix UI',
    url: 'https://www.radix-ui.com/',
    info: ''
  },
  {
    text: 'lib: Material UI',
    url: 'https://mui.com/',
    info: ''
  },
  {
    text: 'lib: Chakra UI',
    url: 'https://chakra-ui.com/',
    info: ''
  },
  {
    text: 'styled components',
    url: 'https://styled-components.com/',
    info: 'looks interesting'
  },
  {
    text: 'testing: jestjs (tutorial)',
    url: 'https://jestjs.io/docs/tutorial-react',
    info: ''
  },
  {
    text: 'testing: react-testing -library',
    url: 'https://testing-library.com/docs/react-testing-library/intro/',
    info: ''
  },
  {
    text: 'testing: enzyme',
    url: 'https://enzymejs.github.io/enzyme/',
    info: ''
  },
  {
    text: 'redux',
    url: 'https://redux.js.org/',
    info: 'predictable state container for JS apps'
  }
]

const obj = { title, data }

export default obj